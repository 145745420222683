<template>
  <div>
    <CouponArea />
    <section class="checkout-area pb-70">
      <div class="container">
        <form @submit.prevent="handleCheckout">
          <div class="row">
            <div class="col-lg-6">
              <div class="checkbox-form">
                <h3>Billing Details</h3>
                <div class="row">
                  <div class="col-md-12">
                    <div class="country-select">
                      <label>Country <span class="required">*</span></label>
                      <select v-model="billing.country">
                        <option value="United Kingdom">United Kingdom</option>
                        <!-- Add more countries as needed -->
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="checkout-form-list">
                      <label>First Name <span class="required">*</span></label>
                      <input v-model="billing.firstName" type="text" placeholder="" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="checkout-form-list">
                      <label>Last Name <span class="required">*</span></label>
                      <input v-model="billing.lastName" type="text" placeholder="" />
                    </div>
                  </div>
                  <!-- Add more billing details fields here -->
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="your-order mb-30">
                <h3>Your order</h3>
                <div class="your-order-table table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th class="product-name">Product</th>
                        <th class="product-total">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in cartItems" :key="item.id" class="cart_item">
                        <td class="product-name">
                          {{ item.course.name }} <strong class="product-quantity"> × {{ item.quantity }}</strong>
                        </td>
                        <td class="product-total">
                          <span class="amount">£{{ item.course.price * item.quantity }}</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr class="cart-subtotal">
                        <th>Cart Subtotal</th>
                        <td><span class="amount">£{{ cartSubtotal }}</span></td>
                      </tr>
                      <tr class="order-total">
                        <th>Order Total</th>
                        <td><strong><span class="amount">£{{ cartTotal }}</span></strong></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="order-button-payment mt-20">
                  <div id="card-element">
                    <!-- A Stripe Element will be inserted here. -->
                  </div>
                  <div id="card-errors" role="alert"></div>
                  <button type="submit" :disabled="isProcessing">Place order</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import CouponArea from './CouponArea.vue';

export default {
  name: 'CheckoutArea',
  components: {
    CouponArea,
  },
  data() {
    return {
      billing: {
        country: '',
        firstName: '',
        lastName: '',
      },
      stripe: null,
      card: null,
      isProcessing: false,
    };
  },
  computed: {
    cartItems() {
      return this.$store.getters['cart/cartItems'];
    },
    cartSubtotal() {
      return this.$store.getters['cart/cartSubtotal'];
    },
    cartTotal() {
      return this.$store.getters['cart/cartTotal'];
    },
  },
  async mounted() {
    const stripeKey = process.env.VUE_APP_STRIPE_KEY;

    if (!stripeKey) {
      console.error('Stripe public key is not defined in environment variables.');
      return;
    }

    this.stripe = await loadStripe(stripeKey);

    if (!this.stripe) {
      console.error('Failed to initialize Stripe.');
      return;
    }

    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount('#card-element');

    this.card.addEventListener('change', (event) => {
      const errorElement = document.getElementById('card-errors');
      if (event.error) {
        errorElement.textContent = event.error.message;
      } else {
        errorElement.textContent = '';
      }
    });
  },
  methods: {
    async handleCheckout() {
      this.isProcessing = true;
      try {
        const { token, error } = await this.stripe.createToken(this.card);
        if (error) {
          this.showError(error);
          this.isProcessing = false;
          return;
        }

        await this.$store.dispatch('cart/processPayment', { token, billing: this.billing });

        alert('Payment successful!');
        // Optionally, redirect or clear cart
        // this.$router.push('/order-confirmation');
        // await this.clearCart();
      } catch (error) {
        console.error('Error processing payment:', error);
        alert('Payment processing error! Please try again later.');
      } finally {
        this.isProcessing = false;
      }
    },
    showError(error) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
    },
  },
  async created() {
    try {
      await this.$store.dispatch('cart/fetchCart');
    } catch (err) {
      console.error('Failed to load cart data:', err.message);
    }
  },
};
</script>

 <style scoped>
 #card-element {
   background: white;
   padding: 10px;
   border-radius: 4px;
 }
 #card-errors {
   color: red;
 }
 </style>
