<template>
    <section class="course-content">
        <!--Dashbord Student -->
            <div class="page-content">
                <div class="container">
                    <div class="row">
                        
                        <!-- Sidebar -->
                        <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
                            <div class="settings-widget dash-profile">
                                <div class="settings-menu p-0">
                                    <div class="profile-bg">
                                        <h5>Beginner</h5>
                                        <img src="../../assets/assets/img/instructor-profile-bg.jpg" alt="">
                                        <div class="profile-img">
                                            <a href="instructor-profile.html"><img src="../../assets/assets/img/user/user15.jpg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="profile-group">
                                        <div class="profile-name text-center">
                                            <h4><a href="instructor-profile.html">Jenny Wilson</a></h4>
                                            <p>Instructor</p>
                                        </div>
                                        <div class="go-dashboard text-center">
                                            <a href="add-course.html" class="btn btn-primary">Create New Course</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-widget account-settings">
                                <div class="settings-menu">
                                    <h3>DASHBOARD</h3>
                                    <ul>
                                        <li class="nav-item ">
                                            <a href="instructor-dashboard.html" class="nav-link">
                                                <i class="feather-home"></i> My Dashboard
                                            </a>
                                        </li>
                                        <li class="nav-item ">
                                            <a href="instructor-course.html" class="nav-link">
                                                <i class="feather-book"></i> My Courses
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-reviews.html" class="nav-link">
                                                <i class="feather-star"></i> Reviews
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-earnings.html" class="nav-link">
                                                <i class="feather-pie-chart"></i> Earnings
                                            </a>
                                        </li>
                                        <li class="nav-item ">
                                            <a href="instructor-orders.html" class="nav-link">
                                                <i class="feather-shopping-bag"></i> Orders
                                            </a>
                                        </li>
                                        <li class="nav-item ">
                                            <a href="instructor-student-grid.html" class="nav-link">
                                                <i class="feather-users"></i> Students
                                            </a>
                                        </li>
                                        <li class="nav-item ">
                                            <a href="instructor-payouts.html" class="nav-link">
                                                <i class="feather-dollar-sign"></i> Payouts
                                            </a>
                                        </li>
                                        <li class="nav-item active">
                                            <a href="instructor-tickets.html" class="nav-link">
                                                <i class="feather-server"></i> Appointments
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="instructor-title">
                                        <h3>ACCOUNT SETTINGS</h3>
                                    </div>
                                    <ul>
                                        <li class="nav-item">
                                            <a href="instructor-edit-profile.html" class="nav-link ">
                                                <i class="feather-settings"></i> Edit Profile
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-security.html" class="nav-link">
                                                <i class="feather-user"></i> Security
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-social-profiles.html" class="nav-link">
                                                <i class="feather-refresh-cw"></i> Social Profiles
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-notification.html" class="nav-link">
                                                <i class="feather-bell"></i> Notifications
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-profile-privacy.html" class="nav-link">
                                                <i class="feather-lock"></i> Profile Privacy
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-delete-profile.html" class="nav-link">
                                                <i class="feather-trash-2"></i> Delete Profile
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="instructor-linked-account.html" class="nav-link">
                                                <i class="feather-user"></i> Linked Accounts
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a href="index.html" class="nav-link">
                                                <i class="feather-power"></i> Sign Out
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>					
                        </div>
                        <!-- /Sidebar -->
                        
                        <!-- Profile Details -->
                        <div class="col-xl-9 col-md-8">	
                            <div class="settings-top-widget">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="card stat-info ttl-tickets">
                                            <div class="card-body">
                                                <img src="../../assets/assets/img/students/ticket-total.svg" alt="Total Tickets">
                                                <h3>50</h3>
                                                <p>New Appointments</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="card stat-info open-tickets">
                                            <div class="card-body">
                                                <img src="../../assets/assets/img/students/ticket-open.svg" alt="Open Tickets">
                                                <h3>30</h3>
                                                <p>Accepted</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="card stat-info close-tickets">
                                            <div class="card-body">
                                                <img src="../../assets/assets/img/students/ticket-close.svg" alt="Close Tickets">
                                                <h3>20</h3>
                                                <p>Cancelled</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="settings-widget">
                                <div class="settings-inner-blk p-0">
                                    <div class="comman-space pb-0">
                                        <div class="filter-grp ticket-grp d-flex align-items-center justify-content-between">
                                            <div>
                                                <h3>Support Tickets</h3>
                                                <p>You can find all of your order Invoices.</p>
                                            </div>
                                            <div class="ticket-btn-grp">
                                                <a href="instructor-new-tickets.html">Add New Ticket</a>
                                            </div>
                                        </div>
                                        
                                        <!-- Ticket Tab -->
                                        <div class="category-tab tickets-tab-blk">
                                            <ul class="nav nav-justified ">
                                                <li class="nav-item"><a href="#all" class="nav-link active" data-bs-toggle="tab" >All</a></li>
                                                <li class="nav-item"><a href="#open" class="nav-link" data-bs-toggle="tab" >New</a></li>
                                                <li class="nav-item"><a href="#open" class="nav-link" data-bs-toggle="tab" >Accepted</a></li>
                                                <li class="nav-item"><a href="#inprogress" class="nav-link" data-bs-toggle="tab" >Cancelled</a></li>
                                                <li class="nav-item"><a href="#close" class="nav-link" data-bs-toggle="tab" >Closed</a></li>
                                            </ul>
                                        </div>
                                        <!-- /Ticket Tab -->

<!-- Appointment List -->
<div class="tab-content">
                                        <div class="tab-pane fade show active" id="all">
                                            <div class="settings-tickets-blk table-responsive">

                                                <!-- Appointments Table -->
                                                <table class="table table-nowrap mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th>Time</th>
                                                            <th>Course</th>
                                                            <th>Client</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <!-- Loading State -->
                                                        <tr v-if="loading">
                                                            <td colspan="5">Loading appointments...</td>
                                                        </tr>
                                                        <!-- Error State -->
                                                        <tr v-if="error">
                                                            <td colspan="5">{{ error }}</td>
                                                        </tr>
                                                        <!-- Appointment Data -->
                                                        <tr v-for="appointment in appointments" :key="appointment.id">
                                                            <td>{{ appointment.appointment_time.split(' ')[0] }}</td>
                                                            <td>{{ appointment.appointment_time.split(' ')[1] }}</td>
                                                            <td>{{ appointment.course_id }}</td>
                                                            <td>{{ appointment.user_id }}</td>
                                                            <td><span :class="getStatusClass(appointment.status)">{{ appointment.status }}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <!-- /Appointments Table -->

                                            </div>
                                        </div>

                                        <!-- You can similarly update the other tab panes if needed -->
                                    </div>
                                    <!-- /Appointment List -->
                                        
                                    </div>
                                </div>
                            </div>
                        </div>	
                        <!-- Profile Details -->
                        
                    </div>
                </div>
            </div>	
            <!-- /Dashbord Student -->
    </section>
</template>

<script>
import axios from 'axios';

export default {
    name: 'InstructorAppointment',
    data() {
        return {
            appointments: [], // Initialize appointments array
            loading: true,    // Loading state
            error: null,      // Error state
            instructorId: null // Current instructor ID
        };
    },
    methods: {
        async fetchAppointments() {
            try {
                if (!this.instructorId) {
                    throw new Error('Instructor ID is not available');
                }

                const response = await axios.get(`/api/appointments/by-instructor/${this.instructorId}`);

                // Ensure that the response contains an array of appointments
                if (response.data && Array.isArray(response.data)) {
                    this.appointments = response.data;
                } else {
                    throw new Error('Unexpected API response format');
                }

                // Log the appointments to debug the structure
                console.log('Appointments:', this.appointments);
                
            } catch (error) {
                this.error = 'Failed to load appointments. Please try again later.';
                console.error('Error fetching appointments:', error);
            } finally {
                this.loading = false;
            }
        },
        getStatusClass(status) {
            // Return appropriate class based on status
            switch (status) {
                case 'Upcoming':
                    return 'badge-info';
                case 'Completed':
                    return 'badge-success';
                case 'Cancelled':
                    return 'badge-danger';
                default:
                    return 'badge-secondary';
            }
        }
    },
    computed: {
        userId() {
        return this.$store.getters.userId; // Directly access userId from Vuex store
        },
    },
    async mounted() {
        // Assume you have a way to get the current instructor ID, e.g., from a Vuex store or route params
        // Assign the instructorId from the store
        this.instructorId = this.userId;

        if (!this.instructorId) {
        console.error("Instructor ID is not available");
        } else {
        this.fetchAppointments(); // Fetch appointments with the instructorId
        }
    }
};
</script>
