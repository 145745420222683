import { createWebHistory,createRouter } from "vue-router";
import store from "@/store/store"; // Import Vuex store instance

import Home from '../pages/Home.vue';

import NewCourseLessonPage from '../pages/NewDynamicCourseLesson.vue';
import NewDashboard from '../pages/NewDashboard.vue';
import NewDashboardAppointment from '../pages/NewDashboardAppointment.vue';

import NewInstructorDashboard from '../pages/NewInstructorDashboard.vue';


import NewCourseLesson from '../pages/NewCourseLesson.vue';
import NewStudentSetting from '../pages/NewStudentSetting.vue';
import NewStudentSecurity from '../pages/NewStudentSecurity.vue';
import NewStudentDelete from '../pages/NewStudentDelete.vue';

import Cart from '../pages/Cart.vue';
import Checkout from '../pages/Checkout.vue';



import Courses from '../pages/Courses.vue';
import DBSservices from '../pages/DBSservices.vue';

import CourseDetails from '../pages/CourseDetails.vue';
import Blog from '../pages/Blog.vue';

import About from '../pages/About.vue';

import InstructorDetails from '../pages/InstructorDetails.vue';
import EventDetails from '../pages/EventDetails.vue';

import SignIn from '../pages/SignIn.vue';
import SignUp from '../pages/SignUp.vue';
import ErrorPage from '../pages/ErrorPage.vue';
import Contact from '../pages/Contact.vue';
import CourseDetailsPage from '../pages/DynamicCourseDetails.vue';
import DynamicBlogDetails from '../pages/DynamicBlogDetails.vue';
import NewInstructorAvailability from "../pages/NewInstructorAvailability.vue";

const router = createRouter({
    history: createWebHistory(),
    routes:[
        {
            path:'/instructor-availability',
            component:NewInstructorAvailability,
            meta: {
                requiresAuth: true, // Indicates this route requires authentication
                requiresInstructor: true,
            }
        },
        {
            path:'/instructor-dashboard',
            component:NewInstructorDashboard,
            meta: {
                requiresAuth: true, // Indicates this route requires authentication
                requiresInstructor: true,
            }
        },
        {
            path: '/course-lesson/:courseId/appointments',
            component: NewDashboardAppointment,
            props: true,
            meta: { 
                requiresAuth: true,  // Ensure only authenticated users can access
              }
        },
        {
            path:'/checkout',
            component:Checkout,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/cart',
            component:Cart,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/course-lesson/:id',
            component:NewCourseLessonPage,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/dashboard',
            component:NewDashboard,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/course-lesson',
            component:NewCourseLesson,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/student-setting',
            component:NewStudentSetting,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/student-security',
            component:NewStudentSecurity,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/student-delete',
            component:NewStudentDelete,
            meta: {
                requiresAuth: true // Indicates this route requires authentication
              }
        },
        {
            path:'/',
            component:Home,
        },
        {
            path:'/home',
            component:Home,
        },

        {
            path:'/courses',
            component:Courses,
        },
        {
            path:'/dbs-services',
            component:DBSservices,
        },

        {
            path:'/course-details',
            component:CourseDetails,
        },
        {
            path:'/blog',
            component:Blog,
        },

        {
            path:'/about',
            component:About,
        },

        {
            path:'/instructor-details',
            component:InstructorDetails,
        },
        {
            path:'/event-details',
            component:EventDetails,
        },
        
        {
            path:'/login',
            component:SignIn,
        },
        {
            path:'/register',
            component:SignUp,
        },
        
        {
            path:'/contact',
            component:Contact,
        },
        {
            path:'/course-details/:id',
            component:CourseDetailsPage,
        },
        {
            path:'/blog-details/:id',
            component:DynamicBlogDetails,
        },
        {
            // Catch-all route for unknown routes
            path: '/:pathMatch(.*)*',
            component: ErrorPage,
        },
    ]
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isAuthenticated) {
            next({
                path: '/login',
                query: { redirect: to.fullPath } // Save the original destination in query
            });
        } else {
            next();
            window.scrollTo(0, 0);
        }
    } else {
        next();
        window.scrollTo(0, 0);
    }
});

// Simulating a function that fetches user id (e.g., from an API or localStorage)
function getUserRole() {
  // Example: Fetching from localStorage, you can replace this with an API call
  return store.getters.userId;  // Example values: 'student', 'instructor', etc.
}

// Add navigation guard to check for the role
router.beforeEach((to, from, next) => {
    const userRole = getUserRole();  // Get the user's role
  
    if (to.matched.some(record => record.meta.requiresInstructor)) {
      if (userRole !== 1) {
        // Redirect to home or another page if user is not an instructor
        alert('You do not have access to this page.');
        next({ name: 'home' });
      } else {
        next();  // If user is an instructor, allow access
      }
    } else {
      next();  // If no specific guard is required, allow access
    }
  });

export default router