<template>
  <section class="cart-area pt-100 pb-100">
    <div class="container">
      <!-- Loading State -->
      <div v-if="loading" class="text-center">
        <p>Loading cart...</p>
      </div>
      <!-- Error State -->
      <div v-else>
        <div v-if="error" class="alert alert-danger">
          <p>{{ error }}</p>
        </div>
        <!-- Cart Content -->
        <div v-else>
          <div class="row">
            <div class="col-12">
              <div class="table-content table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="product-thumbnail">Images</th>
                      <th class="cart-product-name">Product</th>
                      <th class="product-price">Unit Price</th>
                      <th class="product-quantity">Quantity</th>
                      <th class="product-subtotal">Total</th>
                      <th class="product-remove">Remove</th>
                    </tr>
                  </thead>
                  <tbody class="border-0">
                    <tr v-for="item in cartItems" :key="item.id">
                      <td class="product-thumbnail">
                        <router-link v-if="item.course && item.course.id" :to="`/course-details/${item.course.id}`">
                          <img :src="getImagePath(item.course.image_path)" alt="Course Image">
                        </router-link>
                        <span v-else>Course not available</span>
                      </td>
                      <td class="product-name">
                        <router-link v-if="item.course && item.course.id" :to="`/course-details/${item.course.id}`">
                          {{ item.course.title }}
                        </router-link>
                        <span v-else>Unknown Course</span>
                      </td>
                      <td class="product-price">
                        <span class="amount">£{{ item.course.price }}</span>
                      </td>
                      <td class="product-quantity text-center">
                        <div class="product-quantity mt-10 mb-10">
                          <div class="product-quantity-form">
                            <form @submit.prevent>
                              <button class="cart-minus" @click="updateQuantity(item, -1)">
                                <i class="far fa-minus"></i>
                              </button>
                              <input class="cart-input" type="text" :value="item.quantity"/>
                              <button class="cart-plus" @click="updateQuantity(item, 1)">
                                <i class="far fa-plus"></i>
                              </button>
                            </form>
                          </div>
                        </div>
                      </td>
                      <td class="product-subtotal">
                        <span class="amount">£{{ item.course.price * item.quantity }}</span>
                      </td>
                      <td class="product-remove">
                        <a href="#" @click.prevent="removeFromCart(item.id)">
                          <i class="fa fa-times"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- Coupon and Update Cart Section -->
          <div class="row">
            <div class="col-12">
              <div class="coupon-all">
                <div class="coupon d-sm-flex align-items-center">
                  <input id="coupon_code" class="input-text" name="coupon_code" placeholder="Coupon code" type="text">
                  <button class="e-btn" name="apply_coupon" type="submit">Apply coupon</button>
                </div>
                <div class="coupon2">
                  <button class="e-btn" :disabled="updating" type="submit">
                    {{ updating ? 'Updating cart...' : 'Update cart' }}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <!-- Cart Totals and Checkout Section -->
          <div class="row">
            <div class="col-md-5 ml-auto">
              <div class="cart-page-total">
                <h2>Cart totals</h2>
                <ul class="mb-20">
                  <li>Subtotal <span>£{{ cartSubtotal }}</span></li>
                  <li>Total <span>£{{ cartTotal }}</span></li>
                </ul>
                <router-link class="e-btn e-btn-border" to="/checkout">Proceed to checkout</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CartArea",
  data() {
    return {
      loading: true,
      error: null,
      updating: false, // Track updating state
    };
  },
  computed: {
    cartItems() {
      return this.$store.getters['cart/cartItems'];
    },
    cartSubtotal() {
      return this.$store.getters['cart/cartSubtotal'];
    },
    cartTotal() {
      return this.$store.getters['cart/cartTotal'];
    }
  },
  methods: {
    getImagePath(imagePath) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:8000'; // Fallback URL
      return imagePath
        ? `${baseUrl}/storage/${imagePath}`
        : `${baseUrl}/storage/profile_photos/placeholder.jpg`; // Placeholder image URL
    },
    async fetchCart() {
      try {
        await this.$store.dispatch('cart/fetchCart');
      } catch (err) {
        this.error = 'Failed to load cart data. Please try again later.';
      } finally {
        this.loading = false;
      }
    },
    async removeFromCart(itemId) {
      try {
        this.updating = true; // Set updating to true

        const cartItem = this.cartItems.find(item => item.id === itemId);
        if (!cartItem) throw new Error(`Cart item with ID ${itemId} not found`);

        const courseId = cartItem.course.id;

        await this.$store.dispatch('cart/removeFromCart', courseId);
        await this.fetchCart();
      } catch (err) {
        console.error('Failed to remove item from cart:', err.message);
      } finally {
        this.updating = false; // Reset updating to false after operation
      }
    },
    updateQuantity(item, change) {
      const newQuantity = Math.max(1, item.quantity + change);
      this.$store.dispatch('cart/updateQuantity', { itemId: item.id, quantity: newQuantity });
    }
  },
  async created() {
    await this.fetchCart(); // Fetch cart data when component is created
  }
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
