<template>
<!-- Student Header -->
<div class="course-student-header" style="padding-top: 150px;">
    <div class="container">
        <div class="student-group">
            <div class="course-group ">
                <div class="course-group-img d-flex">
                    <a href="student-profile.html"><img :src="userProfileImageUrl" alt="Profile Image" class="img-fluid" /></a>
                    <div class="d-flex align-items-center">
                        <div class="course-name">
                            <h4><a href="student-profile.html">Rolands R</a><span>Beginner</span></h4>
                            <p>Student</p>
                        </div>
                    </div>
                </div>
                <div class="course-share ">
                    <router-link to="/student-setting" class="btn btn-primary">Account Settings</router-link>                </div>
            </div>
        </div>
        <div class="my-student-list">
            <ul>
                <li><a class="active" href="course-student.html"><router-link to="/dashboard">Courses</router-link></a></li>
                <li><a href="course-wishlist.html">Sessions</a></li>
                <li class="mb-0"><a href="purchase-history.html">Purchase history</a></li>
                <li class="mb-0"><a href="purchase-history.html"><router-link to="/learning-resources">Learning Resources</router-link></a></li>
            </ul>
        </div>
    </div>
</div>
<!-- /Student Header -->
</template>
<script>
import axios from 'axios';

export default {
    name:'StudentModule',
  data() {
    return {
      imageFile: null,
      user: null // Initialize user object here
    };
  },
  methods: {
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
    },
    async uploadImage() {
    // Step 1: Request CSRF token
    await axios.get('/sanctum/csrf-cookie');

    // Step 2: Create a FormData object and append the file
    const formData = new FormData();
    formData.append('photo', this.imageFile);

    try {
        // Step 3: Send a POST request to the server
        const response = await axios.post('/upload-image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                //'X-XSRF-TOKEN': this.getCsrfToken(), // Include CSRF token in the headers
            }
        });
        console.log('Image upload successful:', response.data);
        } catch (error) {
            console.error('Image upload failed:', error);
        }
    },
    getCsrfToken() {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    },
    async fetchUserData() {
      try {
        // Fetch user data from API
        const response = await axios.get('/api/user');

        console.log('user:', response.data);

        // Extract the user object from the API response
        const userData = response.data;

        // Assign the user object to the user data property
        this.user = userData;
        
        console.log('user photo path:', this.user.profile_photo_path);
        const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL; 

        console.log('Profile image URL:', `${storageUrl}/${this.user.profile_photo_path}`);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    }
  },
  mounted() {
    // Fetch user data when component is mounted
    this.fetchUserData();
  },
  computed: {
    userProfileImageUrl() {
      // Construct the full URL for the profile image
      const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL; 
      if (this.user && this.user.profile_photo_path) {
        
        return `${storageUrl}/profile_photos/${this.user.profile_photo_path}`;
      }
      // Return a placeholder image URL if no profile image path is available
      return `${storageUrl}/profile_photos/placeholder.jpg`;
    }
  }
};
</script>