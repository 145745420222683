<template>
  <Header header__white="header__white" />
  
  <section class="page__title-area page__title-height-2 page__title-overlay d-flex align-items-center" :style="{background:'url('+require('../assets/img/page-title/page-title-3.jpg')+')',backgroundPosition:'center',backgroundSize:'cover',backgroundRepeat:'no-repeat'}">
   <div class="page__title-shape">
      <img class="page-title-shape-1" src="../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-2" src="../assets/img/page-title/page-title-shape-2.png" alt="">
      <img class="page-title-shape-3" src="../assets/img/page-title/page-title-shape-3.png" alt="">
      <img class="page-title-shape-4" src="../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-10 col-xl-10 col-lg-10 ">
            <div class="page__title-wrapper mt-110">
               <span class="page__title-pre">{{blog.category}}</span>
               <h3 class="page__title-2">{{blog.title}}</h3> 
               <div class="blog__meta d-flex align-items-center">
                  <div class="blog__author d-flex align-items-center mr-40">
                     <div class="blog__author-thumb mr-10">
                        <img :src="blog.authorImg" alt="">
                     </div>
                     <div class="blog__author-info blog__author-info-2">
                        <h5>{{blog.authorName}}</h5>
                     </div>
                  </div>
                  <div class="blog__date blog__date-2 d-flex align-items-center">
                     <i class="fal fa-clock"></i>
                     <span>{{blog.date}}</span>
                  </div>
               </div>                      
            </div>
         </div>
      </div>
   </div>
</section>

   <section class="blog__area pt-120 pb-120">
      <div class="container">
         <div class="row">
            <div class="col-xxl-8 col-xl-8 col-lg-8">
               <div class="blog__wrapper">
                  <div class="blog__text mb-40">
                     <p>{{ blog.content }}</p>
                  </div>
                  <div class="blog__quote grey-bg mb-45 p-relative fix">
                     <img class="quote" src="../assets/img/blog/quote-1.png" alt="">
                     <blockquote>
                        <p>{{ blog.quote }}</p>
                     </blockquote>
                  </div>
                  <div class="blog__line"></div>
                  <div class="blog__meta-3 d-sm-flex justify-content-between align-items-center mb-80">
                     <div class="blog__tag-2">
                        <a href="#">Childcare</a>
                     </div>
                     <div class="blog__social d-flex align-items-center">
                        <h4>Share:</h4>
                        <ul>
                           <li><a href="#" class="fb" >
                              <i class="fab fa-facebook-f"></i>
                              </a></li>
                           <li><a href="#" class="tw" >
                              <i class="fab fa-twitter"></i>
                              </a></li>
                           <li><a href="#" class="pin" >
                              <i class="fab fa-pinterest-p"></i>
                              </a></li>
                        </ul>
                     </div>
                  </div>
                  <div class="blog__author-3 d-sm-flex grey-bg mb-90">
                     <div class="blog__author-thumb-3 mr-20">
                        <img :src="blog.authorImg" alt="">
                     </div>
                     <div class="blog__author-content">
                        <h4>{{blog.authorName}}</h4>
                        <span>Author</span>
                        <p>We recognize that each child is unique, and our courses emphasize the importance of tailored curricula that cater to individual needs.</p>
                     </div>
                  </div>
                  <div class="blog__recent mb-65">
                     <div class="section__title-wrapper mb-40">
                        <h2 class="section__title">Related <span class="yellow-bg-sm">Post <img src="../assets/img/shape/yellow-bg-4.png" alt="">  </span></h2>
                        <p>You don't have to struggle alone, you've got our assistance and help.</p>
                     </div>
                     <div class="row">
                        <div v-for="blog in blogItems.slice(0,2)" :key="blog.id" class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="blog__item white-bg mb-30 transition-3 fix">
                              <div class="blog__thumb w-img fix">
                                 <router-link :to="`/blog-details/${blog.id}`">
                                       <img :src="blog.blogImg" alt="">
                                 </router-link>
                              </div>
                              <div class="blog__content">
                                 <div class="blog__tag">
                                    <a href="#" :class="blog.color">{{blog.category}}</a>
                                 </div>
                                 <h3 class="blog__title">
                                     <router-link :to="`/blog-details/${blog.id}`">
                                     {{blog.title}}</router-link>
                                 </h3>
                                 <div class="blog__meta d-flex align-items-center justify-content-between">
                                    <div class="blog__author d-flex align-items-center">
                                       <div class="blog__author-thumb mr-10">
                                          <img :src="blog.authorImg" alt="">
                                       </div>
                                       <div class="blog__author-info">
                                          <h5>{{blog.authorName}}</h5>
                                       </div>
                                    </div>
                                    <div class="blog__date d-flex align-items-center">
                                       <i class="fal fa-clock"></i>
                                       <span>{{blog.date}}</span>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <!-- latest comment start -->
                  
                  <!-- latest comment end -->

                  <!-- blog comment start -->
                  <BlogWriteComment/>
                  <!-- blog comment end -->
               </div>
            </div>
            <!-- BlogRightSide start -->
            <BlogRightSide/>
            <!-- BlogRightSide end -->
         </div>
      </div>
    </section>
  <Footer footerPadding="true" />
</template>

<script>
import BlogItemMixin from '../mixins/blogMixin';
import Header from '../components/Home/Header.vue';
import BlogWriteComment from '../components/BlogDetails/BlogWriteComment.vue';
import BlogRightSide from '../components/Blog/BlogRightSide.vue';
import Footer from '../components/Home/Footer.vue';

export default {
  name: "BlogDetails",
  mixins: [BlogItemMixin],
  components:{
     Header,
     BlogWriteComment,
     BlogRightSide,
     Footer,
  },
    data() {
        return {
            id: this.$route.params.id,
            blog: {},
            blogs:[
                {
                    id:1,
                    blogImg:require(`@/assets/img/blog/blog-1.jpg`),
                    title:'FountainLife Training: Nurturing Bright Futures through Early Childhood Education',
                    content: 'In the journey of a child\u0027s life, the early years serve as the foundation upon which their future is built. At FountainLife Training, we believe in the transformative power of early childhood education, recognizing it as a cornerstone for holistic development. In this childcare course, we delve into the profound impact of early childhood education and how FountainLife Training is contributing to the brighter future of children. Early childhood, often considered the formative years, plays a pivotal role in shaping a child\u0027s cognitive, emotional, and social well-being. Quality early childhood education lays the groundwork for future learning experiences, fostering a love for learning that extends throughout a child\u0027s academic journey. Embark on a journey with FountainLife Training and become a catalyst for positive change in the lives of young learners. Together, let\u0027s build a foundation for a brighter tomorrow through the power of early childhood education. Enroll in our courses today and join a community dedicated to making a lasting impact on the world—one child at a time.',
                    quote: 'Every child is a different kind of flower, and all together, they make this world a beautiful garden.',
                    author:require(`@/assets/img/blog/author/author-1.jpg`),
                    authorName:'Marlen',
                    date:'January 30, 2024',
                    category:'Childcare'
                },
                
            ]
        }
    },
    methods: {
        getBlog(blogId) {
            this.blog = this.blogs.find(item => item.id == blogId);
        }
    },
    created() {
        this.getBlog(this.id);
    }
};
</script>
