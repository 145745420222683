<template>
<!-- Student Header -->
<div class="course-student-header" style="padding-top: 150px;">
    <div class="container">
        <div class="student-group">
            <div class="course-group ">
                <div class="course-group-img d-flex">
                    <a href="student-profile.html"><img src="../../assets/assets/img/user/user11.jpg" alt="" class="img-fluid"></a>
                    <div class="d-flex align-items-center">
                        <div class="course-name">
                            <h4><a href="student-profile.html">Rolands R</a><span>Beginner</span></h4>
                            <p>Student</p>
                        </div>
                    </div>
                </div>
                <div class="course-share ">
                    <router-link to="/student-setting" class="btn btn-primary">Account Settings</router-link>
                </div>
            </div>
        </div>
        <div class="my-student-list">
            <ul>
                <li><a href="course-student.html"><router-link to="/dashboard">Courses</router-link></a></li>
                <li><a href="course-wishlist.html" class="active">Wishlists</a></li>
                <li><a href="course-message.html">Messages</a></li>
                <li class="mb-0"><a href="purchase-history.html">Purchase history</a></li>
            </ul>
        </div>
    </div>
</div>
<!-- /Student Header -->
</template>
<script>
export default {
	name:'StudentModule'
};
</script>