<template>
    <Header/>
    <StudentModule/>
    <CourseEnrollment/>
    <Footer footerPadding="true" />
 </template>
 
 <script>
 import Header from '../components/HomeThree/Header.vue';
 import CourseEnrollment from '../components/NewDashboard/CourseEnrollment.vue';
 import StudentModule from '../components/NewDashboard/Student.vue';
 import Footer from '../components/Home/Footer.vue';
 
 export default {
    name:'NewDashboard',
    components:{
       Header,
       StudentModule,
       CourseEnrollment,
       Footer,
    }
 };
 </script>

 