<template>
   <section class="about__area pt-90 pb-150">
      <div class="container">
         <div class="row">
            <div class="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
               <div class="about__thumb-wrapper">
                  <div class="about__thumb ml-100">
                     <img src="../../assets/img/about/about.jpg" alt="">
                  </div>
                  <div class="about__banner mt--210">
                     <img src="../../assets/img/about/about-banner.jpg" alt="">
                  </div>
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6">
               <div class="about__content pl-70 pr-60 pt-25">
                  <div class="section__title-wrapper mb-25">
                     <h2 class="section__title">Welcome to<br><span class="yellow-bg-big">fountainlife <img src="../../assets/img/shape/yellow-bg-2.png" alt="">
                     </span>training company  </h2>
                     <p>We are a NFCE, and OCN accredited centre focusing on the Early Years qualifications, Paediatric First Aid training, and CPD sessions, all led by our vastly experienced qualified team. Our courses are taught all throughout the year in our training centre located in East London as well as by eLearning. Courses can also be delivered in your setting upon request. Our expert team can offer guidance and support to advise you about the many options you have. </p>
                  </div>
                 
                  <router-link to="/register" class="e-btn e-btn-border">Sign up with email</router-link>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'AboutArea'
};
</script>
