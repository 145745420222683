<template>
    <!--Dashbord Student -->
    <div class="page-content">
        <div class="container">
            <div class="row">
                
                <!-- sidebar -->
                <div class="col-xl-3 col-md-4 theiaStickySidebar">
                    <div class="settings-widget dash-profile mb-3">
                        <div class="settings-menu p-0">
                            <div class="profile-bg">
                                <h5>Beginner</h5>
                                <img src="../../assets/assets/img/profile-bg.jpg" alt="">
                                <div class="profile-img">
                                    <a href="student-profile.html"><img src="../../assets/assets/img/user/user11.jpg" alt=""></a>
                                </div>
                            </div>
                            <div class="profile-group">
                                <div class="profile-name text-center">
                                    <h4><a href="student-profile.html">Rolands R</a></h4>
                                    <p>Student</p>
                                </div>
                                <div class="go-dashboard text-center">
                                    <router-link to="/dashboard" class="btn btn-primary">Go to Dashboard</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings-widget account-settings">
                        <div class="settings-menu">
                            <h3>ACCOUNT SETTINGS</h3>
                            <ul>
                                <li class="nav-item ">
                                    <router-link to="/student-setting" class="nav-link">
                                        <i class="feather-settings"></i> Edit Profile  
                                    </router-link>
                                </li>
                                <li class="nav-item active">
                                    <router-link to="/student-security" class="nav-link">
                                        <i class="feather-user"></i> Security   
                                    </router-link>
                                </li>                                
                                <li class="nav-item ">
                                    <router-link to="/student-delete" class="nav-link">
                                        <i class="feather-trash-2"></i> Delete Profile  
                                    </router-link>
                                </li>                                
                                <li class="nav-item">
                                    <a href="login.html" class="nav-link">
                                        <i class="feather-power"></i> Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>			
                </div>
                <!-- /Sidebar -->
                
                <!-- Student Security -->
                <div class="col-xl-9 col-md-8">    
                    <div class="settings-widget profile-details">
                        <div class="settings-menu p-0">
                            <div class="profile-heading">
                                <h3>Security</h3>
                                <p>Edit your account settings and change your password here.</p>
                            </div>
                            <div class="checkout-form personal-address border-line">
                                <div class="personal-info-head">
                                    <h4>Email Address</h4>
                                    <p>Your current email address is <span>maxwell@example.com</span></p>
                                </div>
                                <form @submit.prevent="updateEmail">
                                    <div class="new-address">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="input-block">
                                                    <label class="form-control-label">New email address</label>
                                                    <input v-model="newEmail" type="email" class="form-control" placeholder="Enter your New email address" required>
                                                </div>
                                            </div>
                                            <div class="profile-share d-flex ">
                                                <button type="submit" class="btn btn-success">Update</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="checkout-form personal-address">
                                <div class="personal-info-head">
                                    <h4>Change Password</h4>
                                    <p>We will email you a confirmation when changing your password, so please expect that email after submitting.</p>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <form @submit.prevent="changePassword">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="input-block">
                                                        <label class="form-control-label">Current Password</label>
                                                        <input v-model="currentPassword" type="password" class="form-control" placeholder="Enter your current password" required>
                                                    </div>
                                                    <div class="input-block">
                                                        <label class="form-control-label">New Password</label>
                                                        <input v-model="newPassword" type="password" class="form-control" placeholder="Enter your new password" required>
                                                    </div>
                                                    <div class="input-block">
                                                        <label class="form-control-label">Confirm New Password</label>
                                                        <input v-model="confirmPassword" type="password" class="form-control" placeholder="Confirm your new password" required>
                                                    </div>
                                                    <div class="update-profile save-password">
                                                        <button type="submit" class="btn btn-primary">Save Password</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <!-- Student Security -->
                
            </div>
        </div>
    </div>	
    <!-- /Dashbord Student -->
</template>
<script>
import axios from 'axios';

export default {
    name: 'StudentSecurity',
    data() {
        return {
            newEmail: '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };
    },
    methods: {
        async fetchUserData() {
            try {
                await axios.get('/api/user');
                // No need to use response if you aren't displaying or processing user data
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        },
        async updateEmail() {
            try {
                await axios.post('/api/update-email', {
                    email: this.newEmail
                });
                alert('Email updated successfully!');
            } catch (error) {
                console.error('Error updating email:', error);
                alert('Failed to update email.');
            }
        },
        async changePassword() {
        if (this.newPassword !== this.confirmPassword) {
            alert('New password and confirmation do not match.');
            return;
        }

        if (this.newPassword.length < 8) {
            alert('New password must be at least 8 characters long.');
            return;
        }

        try {
            await axios.post('/api/change-password', {
                current_password: this.currentPassword,
                new_password: this.newPassword,
                new_password_confirmation: this.confirmPassword // Add this field
            });
            alert('Password changed successfully!');
        } catch (error) {
            console.error('Error changing password:', error.response.data);
            
            if (error.response && error.response.data && error.response.data.errors) {
                alert('Validation errors: ' + JSON.stringify(error.response.data.errors));
            } else {
                alert('Failed to change password.');
            }
        }
    }
    },
    mounted() {
        this.fetchUserData();
    }
};
</script>