<template>
    <Header/>
    <StudentModule/>
    <CourseLesson/>
    <Footer footerPadding="true" />
 </template>
 
 <script>
 import Header from '../components/HomeThree/Header.vue';
 import Footer from '../components/Home/Footer.vue';
import CourseLesson from '../components/NewCourseLesson/CourseLesson.vue';
import StudentModule from '../components/NewCourseLesson/Student.vue';
 
 export default {
    name:'NewCourseLesson',
    components:{
       Header,
       StudentModule,
       CourseLesson,
       Footer,
    }
 };
 </script>

 