<template>
  <section class="page-content course-sec course-lesson">
    <div class="container">
      <div class="row">
        <div class="col-lg-4">
          <div class="lesson-group">

            <!-- Loading State -->
            <div v-if="loading" class="text-center">
              <p>Loading course chapters...</p>
            </div>

            <!-- Render Course Cards -->
            <div v-else>
              <div class="course-card" v-for="chapter in chapters" :key="chapter.id">
                <h6 class="cou-title">
                  <a class="collapsed" @click="toggleChapter(chapter.id)" :aria-expanded="chapter.expanded">
                    {{ chapter.title }} <span>{{ chapter.lessonCount }} Lessons</span>
                  </a>
                </h6>
                <div :id="'collapse' + chapter.id" class="card-collapse collapse" :class="{ 'show': chapter.expanded }">
                  <div class="progress-stip">
                    <div class="progress-bar bg-success progress-bar-striped active-stip"></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>{{ chapter.duration }}<span>{{ chapter.progress }}%</span></p>
                  </div>
                  <ul>
                    <li v-for="lesson in chapter.lessons" :key="lesson.id">
                      <p :class="{ 'play-intro': lesson.playable }">{{ lesson.title }}</p>
                      <div>
                        <img :src="lesson.playable ? playIcon : lockIcon" alt="">
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="student-widget lesson-introduction">
            <div class="lesson-widget-group">
              <h4 class="tittle">Introduction</h4>
              <div class="introduct-video">
                <a :href="videoUrl" class="video-thumbnail" data-fancybox="">
                  <div class="play-icon">
                    <i class="fa-solid fa-play"></i>
                  </div>
                  <img class="" :src="videoThumbnail" alt="Video Thumbnail">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import playIcon from '@/assets/assets/img/icon/play-icon.svg'; // Adjust path if needed
import lockIcon from '@/assets/assets/img/icon/lock.svg'; // Adjust path if needed
import videoThumbnail from '@/assets/assets/img/video-img-01.jpg'; // Adjust path if needed

import axios from 'axios';

export default {
  name: 'NewCourseLessonPage',
  data() {
    return {
      videoUrl: '',
      playIcon,
      lockIcon,
      videoThumbnail,
      chapters: [],
      loading: true,  // Loading state
      error: null,    // Error state
    };
  },
  methods: {
    async setupCsrfToken() {
      try {
        await axios.get('/sanctum/csrf-cookie');
      } catch (error) {
        console.error('Error requesting CSRF token:', error);
      }
    },
    getCsrfToken() {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    },
    async fetchChaptersWithLessons(courseId) {
      try {
        await this.setupCsrfToken();
        const response = await axios.get(`/api/courses/${courseId}/chapters`, {
          headers: {
            'X-XSRF-TOKEN': this.getCsrfToken()
          }
        });
        console.log('API Response:', response.data); // Ensure this logs correct data
        this.chapters = response.data.map(chapter => ({
          ...chapter,
          expanded: false // Add this to manage the toggle state
        }));
      } catch (error) {
        this.error = 'Failed to load course chapters. Please try again later.';
        console.error('Error fetching chapters and lessons:', error.response ? error.response.data : error.message);
      } finally {
        this.loading = false; // Set loading to false after data fetch
      }
    },
    async loadVideoUrl(courseId, chapterId, lessonId) {
      try {
        const response = await axios.get(`/api/video/${courseId}/${chapterId}/${lessonId}`, {
          headers: {
            'X-XSRF-TOKEN': this.getCsrfToken()
          }
        });
        this.videoUrl = response.data.url;
        console.log('Video URL:', this.videoUrl);
      } catch (error) {
        console.error('Error loading video URL:', error.response ? error.response.data : error.message);
      }
    },
    toggleChapter(chapterId) {
      const chapter = this.chapters.find(ch => ch.id === chapterId);
      if (chapter) {
        chapter.expanded = !chapter.expanded;
        if (chapter.expanded && chapter.lessons.length > 0) {
          this.loadVideoUrl(chapter.course_id, chapter.id, chapter.lessons[0].id);
          console.log(this.videoUrl);
        }
      }
    },
    async loadChapters() {
      await this.fetchChaptersWithLessons(1);
    }
  },
  mounted() {
    this.loadChapters();
  }
};

</script>