<template>
    <Header/>
    <BannerModule/>
    <InstructorAvailability/>
    <Footer footerPadding="true" />
 </template>
 
<script>
import Header from '../components/HomeThree/Header.vue';
import BannerModule from '../components/NewStudentSetting/BannerModule.vue'; 
import InstructorAvailability from '../components/NewInstructorDashboard/InstructorAvailability.vue';
import Footer from '../components/Home/Footer.vue';

export default {
    name: 'NewInstructorAvailability',
    components: {
        Header,
        BannerModule,
        InstructorAvailability,
        Footer,
    }
};
</script>
