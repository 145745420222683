<template>
    <Header header__white="header__white" />
    <Breadcrumb title="DBS" subtitle="DBS" />
    <DBSservicesArea/>
    <Footer footerPadding="true"/>
 </template>
 
 <script>
 import Header from '../components/Home/Header.vue';
 import Breadcrumb from '../components/common/Breadcrumb.vue';
 import DBSservicesArea from '../components/DBSservices/DBSservices.vue';
 import Footer from '../components/Home/Footer.vue';
 
 export default {
    name:'DBSservices',
    components:{
       Header,
       Breadcrumb,
       DBSservicesArea,
       Footer
    }
 };
 </script>
 