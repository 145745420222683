<template>
  <div class="col-xxl-4 col-xl-4 col-lg-4">
    <div class="course__sidebar pl-70 p-relative">
      <div class="course__shape">
        <img class="course-dot" src="../../assets/img/course/course-dot.png" alt="">
      </div>
      <div class="course__sidebar-widget-2 white-bg mb-20">
        <div class="course__video">
          <div class="course__video-thumb w-img mb-25">
            <img src="../../assets/img/course/video/course-video.jpg" alt="">
            <div class="course__video-play">
              <button v-b-modal.modal-center href="https://youtu.be/yJg-Y5byMMw" data-fancybox="" class="play-btn">
                <i class="fas fa-play"></i>
              </button>
            </div>
          </div>
          <div class="course__video-meta mb-25 d-flex align-items-center justify-content-between">
            <div class="course__video-price">
              <h5>£{{ price }}.<span>00</span></h5>
              <h5 class="old-price">£{{ oldPrice }}</h5>
            </div>
            <div class="course__video-discount">
              <span>up to 10% OFF</span>
            </div>
          </div>
          <div class="course__video-content mb-35">
            <ul>
              <li class="d-flex align-items-center">
                <div class="course__video-icon">
                  <!-- SVG icons here -->
                </div>
                <div class="course__video-info">
                  <h5><span>Instructor :</span> Adeline N. B. </h5>
                </div>
              </li>
              <!-- Other list items -->
            </ul>
          </div>
          <div class="course__payment mb-35">
            <h3>Payment:</h3>
            <a href="#">
              <img src="../../assets/img/course/payment/payment-1.png" alt="">
            </a>
          </div>
          <div class="course__enroll-btn">
            <button @click="handleButtonClick" class="e-btn e-btn-7 w-100">
              {{ buttonText }} <i class="far fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <RelatedCourse DetailsPage="course__sidebar-widget-2 white-bg mb-20" />
    </div>
  </div>

  <!-- Video Modal -->
  <div>
    <div class="video-modal-wrapper">
      <b-modal id="modal-center" centered>
        <iframe src="https://www.youtube.com/embed/yJg-Y5byMMw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios
import { mapGetters } from 'vuex'; // Import mapGetters
import RelatedCourse from '../common/RelatedCourse.vue';

export default {
  name: 'courseSidebar',
  components: {
    RelatedCourse,
  },
  data() {
    return {
      price: 0,         // Default price
      oldPrice: 0,      // Default old price
      buttonText: 'Loading...',
      isLoading: false, // Add a flag to track loading state
    };
  },
  async created() {
    await this.fetchCourseDetails(); // Fetch the course details including the price
    if (this.isAuthenticated) {
      await this.checkEnrollmentStatus();
    } else {
      this.buttonText = 'Login to Enroll';
    }
  },
  methods: {
    async fetchCourseDetails() {
      try {
        const courseId = this.$route.params.id;
        const response = await axios.get(`/api/courses/${courseId}`);
        
        // Assuming the response contains price and oldPrice fields
        this.price = response.data.price;
        this.oldPrice = response.data.oldPrice;
      } catch (error) {
        console.error('Error fetching course details:', error.response ? error.response.data : error.message);
      }
    },
    async checkEnrollmentStatus() {
      if (!this.isAuthenticated) {
        this.buttonText = 'Login to Enroll';
        return;
      }

      try {
        const courseId = this.$route.params.id;
        const token = this.$store.getters.token; // Retrieve token from Vuex store
        console.log('Token being sent:', token); // Debug token value
        //await axios.get('/sanctum/csrf-cookie');
        const response = await axios.get(`/api/courses/${courseId}/enrollment-status`, {
          headers: {
           // 'X-XSRF-TOKEN': this.getCsrfToken(),
            'Authorization': `Bearer ${token}` // Ensure token is sent in headers
          }
        });

        if (response && response.data) {
          const { enrolled, logged_in } = response.data;
          console.log('Enrollment status from API:', { enrolled, logged_in });

          if (!logged_in) {
            this.buttonText = 'Login to Enroll';
          } else if (enrolled) {
            this.buttonText = 'Go to Lessons';
          } else {
            this.buttonText = 'Enroll';
          }
        } else {
          console.error('Response does not contain expected data:', response);
          this.buttonText = 'Error';
        }
      } catch (error) {
        console.error('Error checking enrollment status:', error.response ? error.response.data : error.message);
        this.buttonText = 'Error';
      }
    },
    getCsrfToken() {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    },
    async handleButtonClick() {
      if (!this.isAuthenticated) {
        this.$router.push('/login');
      } else {
        this.isLoading = true; // Set loading flag
        this.buttonText = 'Loading...'; // Update button text

        const courseId = this.$route.params.id;
        try {
          const response = await axios.get(`/api/courses/${courseId}/enrollment-status`);
          const { enrolled } = response.data;
          if (enrolled) {
            this.$router.push('/dashboard');
          } else {
            this.enroll();
          }
        } catch (error) {
          console.error('Error handling button click:', error.response ? error.response.data : error.message);
          this.buttonText = 'Error'; // Handle error state
        } finally {
          this.isLoading = false; // Reset loading flag
        }
      }
    },
    async enroll() {
      if (!this.isAuthenticated) {
        console.error('User not authenticated');
        this.$store.dispatch('clearToken');
        location.reload();
        this.$router.push('/login');
        return;
      }

      try {
        this.isLoading = true; // Set loading flag
        this.buttonText = 'Loading...'; // Update button text

        const courseId = this.$route.params.id;
        const token = this.$store.getters.token; // Retrieve token from Vuex store

        const response = await axios.post('/api/cart/add', { course_id: courseId }, {
          headers: {
           // 'X-XSRF-TOKEN': this.getCsrfToken(),
            'Authorization': `Bearer ${token}` // Ensure token is sent in headers
          }
        });

        console.log('Response from cart add API:', response.data); // Debug response data

        await this.fetchCart(); // Optionally fetch cart data to verify
        this.$router.push('/checkout');
      } catch (error) {
        console.error('Error enrolling:', error.response ? error.response.data : error.message);
        this.buttonText = 'Error'; // Handle error state
      } finally {
        this.isLoading = false; // Reset loading flag
      }
    },
    async fetchCart() {
      if (!this.isAuthenticated) {
        console.log('User not authenticated, skipping cart fetch.');
        return;
      }

      try {
        const response = await axios.get('/api/cart');
        this.cart = response.data;
        console.log('Fetched cart data:', this.cart); // Debug cart data
      } catch (error) {
        console.error('Error fetching cart:', error.response ? error.response.data : error.message);
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      userId: 'userId',
    }),
  },
  mounted() {
    console.log('Mounted userId:', this.userId);
  },
};
</script>
