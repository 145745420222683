<template>
    <Header/>
    <BannerModule/>
    <InstructorAppointment/>
    <Footer footerPadding="true" />
 </template>
 
 <script>
 import Header from '../components/HomeThree/Header.vue';
 import BannerModule from '../components/NewStudentSetting/BannerModule.vue';
 import InstructorAppointment from '../components/NewInstructorDashboard/InstructorAppointment.vue';
import Footer from '../components/Home/Footer.vue';
 
 export default {
    name:'NewInstructorDashboard',
    components:{
       Header,
       BannerModule,
       InstructorAppointment,
       Footer,
    }
 };
 </script>