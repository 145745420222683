<template>
 <section class="page__title-area pt-120 pb-90">
   <div class="page__title-shape">
      <img class="page-title-shape-5 d-none d-sm-block" src="../../assets/img/page-title/page-title-shape-1.png" alt="">
      <img class="page-title-shape-6" src="../../assets/img/page-title/page-title-shape-6.png" alt="">
      <img class="page-title-shape-7" src="../../assets/img/page-title/page-title-shape-4.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__wrapper">
               <div class="page__title-content mb-25">
                  <div class="page__title-breadcrumb">                            
                        <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
                           <li class="breadcrumb-item"><router-link to="/courses">Courses</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Explore Our Comprehensive Courses 2024</li>
                        </ol>
                        </nav>
                  </div>
                  <span class="page__title-pre">Unlocking Potential:</span>
                  <h5 class="page__title-3">Explore Our Comprehensive Courses in Childcare and Education</h5>
               </div>
               <div class="course__meta-2 d-sm-flex mb-30">
                  <div class="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                     <div class="course__teacher-thumb-3 mr-15">
                        <img src="../../assets/img/course/teacher/teacher-10.jpg" alt="">
                     </div>
                     <div class="course__teacher-info-3">
                        <h5>Teacher</h5>
                        <p><a href="#">Marlen</a></p>
                     </div>
                  </div>
                  <div class="course__update mr-80 mb-30">
                     <h5>Last Update:</h5>
                     <p>Jan 29, 2024</p>
                  </div>
                  <div class="course__rating-2 mb-30">
                     <h5>Review:</h5>
                     <div class="course__rating-inner d-flex align-items-center">
                        <ul>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                           <li><a href="#"> <i class="fas fa-star"></i> </a></li>
                        </ul>
                        <p>4.5</p>
                     </div>
                  </div>
               </div>
               <div class="course__img w-img mb-30">
                  <img src="../../assets/img/course/details/course-details-1.jpg" alt="">
               </div>
               <div class="course__tab-2 mb-45">
                  <ul class="nav nav-tabs" id="courseTab" role="tablist">
                     <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="description-tab" data-bs-toggle="tab" data-bs-target="#description" type="button" role="tab" aria-controls="description" aria-selected="true"> <i class="icon_ribbon_alt"></i> <span>Discription</span> </button>
                     </li>
                     <li class="nav-item" role="presentation">
                        <button class="nav-link " id="curriculum-tab" data-bs-toggle="tab" data-bs-target="#curriculum" type="button" role="tab" aria-controls="curriculum" aria-selected="false"> <i class="icon_book_alt"></i> <span>Curriculum</span> </button>
                     </li>
                     <li class="nav-item" role="presentation">
                        <button class="nav-link" id="review-tab" data-bs-toggle="tab" data-bs-target="#review" type="button" role="tab" aria-controls="review" aria-selected="false"> <i class="icon_star_alt"></i> <span>Reviews</span> </button>
                     </li>
                     <li class="nav-item" role="presentation">
                        <button class="nav-link" id="member-tab" data-bs-toggle="tab" data-bs-target="#member" type="button" role="tab" aria-controls="member" aria-selected="false"> <i class="fal fa-user"></i> <span>Members</span> </button>
                     </li>
                     </ul>
               </div>
               <div class="course__tab-content mb-95">
                  <div class="tab-content" id="courseTabContent">
                     <div class="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                        <div class="course__description">
                           <h3>Course Overview</h3>
                           <p>Are you passionate about making a positive impact on children's lives or seeking to enhance your skills in the field of childcare and education?
                              Look no further than our diverse range of courses designed to empower individuals with the knowledge and expertise needed to excel in the dynamic world of childcare.</p>
                           <b>1. Maths and English Level 2:</b>
                           <p>Sharpen your foundational skills with our Maths and English Level 2 courses. Tailored to meet the highest standards, these courses provide essential tools for effective 
                              communication and problem-solving in childcare environments.</p>
                           <b>2. Common Core Skills:</b>
                           <p>Master the fundamental competencies required in childcare settings through our Common Core Skills course. Delve into key principles, communication strategies, 
                              and best practices that form the backbone of a successful childcare career.</p>
                           <b>3. Childminding Course:</b>
                           <p>Embark on the rewarding journey of childminding with our specialized course. Gain insights into creating nurturing environments, fostering positive relationships, 
                              and managing day-to-day responsibilities to become a confident and effective childminder.</p>
                           <b>4. Safeguarding:</b>
                           <p>Ensuring the safety and well-being of children is paramount. Our Safeguarding course equips you with the knowledge and protocols necessary to create secure environments, 
                              recognize signs of concern, and take proactive measures to protect children.</p>
                           <b>5. Food Safety:</b>
                           <p>Understand the importance of nutrition and hygiene in childcare settings with our Food Safety course. Learn best practices for preparing and serving food, 
                              fostering healthy eating habits, and maintaining a safe and clean food environment.</p>
                           <b>6. Adult Care Level 3:</b>
                           <p>Broaden your expertise with our Adult Care Level 3 course. Explore advanced concepts in adult care, from health and safety protocols to person-centered approaches, 
                              preparing you for a fulfilling career in adult care services.</p>
                           <b>7. Early Years Educator:</b>
                           <p>Become a catalyst for early childhood development with our Early Years Educator course. Delve into the principles of child development, curriculum planning, 
                              and effective teaching strategies to become a highly skilled and compassionate early years educator.</p>
                           <br>
                           <p>At Fountainlife training, we believe in nurturing potential and shaping futures. Our courses are crafted to empower individuals like you with the knowledge, skills, and confidence needed to excel in the dynamic and fulfilling field of childcare and education.
                               Join us on this transformative journey and unlock your potential today.</p>
                              <div class="course__tag-2 mb-35 mt-35">
                              <i class="fal fa-tag"></i>
                              <a href="#">Childcare,</a>
                              <a href="#">Adultcare,</a>
                              <a href="#">Heathcare</a>
                           </div>
                           <div class="course__description-list mb-45">
                              <h4>What is the Target Audience?</h4>
                              <ul>
                                 <li> <i class="icon_check"></i>Childcare providers, nursery workers, preschool teachers, or childminders.</li>
                                 <li> <i class="icon_check"></i>Parents and guardians who want to gain a deeper understanding of child development.</li>
                                 <li> <i class="icon_check"></i>Entrepreneurs and individuals interested in starting their own childcare center.</li>
                                 <li> <i class="icon_check"></i>Individuals from various professional backgrounds considering a career change.</li>
                              </ul>
                           </div>
                           <div class="course__instructor mb-45">
                              <h3>Other Instructors</h3>
                              <div class="course__instructor-wrapper d-md-flex align-items-center">
                                 <div class="course__instructor-item d-flex align-items-center mr-70">
                                    <div class="course__instructor-thumb mr-20">
                                       <img src="../../assets/img/course/teacher/teacher-10.jpg" alt="">
                                    </div>
                                    <div class="course__instructor-content">
                                       <h3>Marlen</h3>
                                       <p>Instructor</p>
                                    </div>
                                 </div>
                                 <div class="course__instructor-item d-flex align-items-center mr-70">
                                    <div class="course__instructor-thumb mr-20">
                                       <img src="../../assets/img/course/teacher/teacher-2.jpg" alt="">
                                    </div>
                                    <div class="course__instructor-content">
                                       <h3>Joel</h3>
                                       <p>Teacher</p>
                                    </div>
                                 </div>
                                 <div class="course__instructor-item d-flex align-items-center mr-70">
                                    <div class="course__instructor-thumb mr-20">
                                       <img src="../../assets/img/course/teacher/teacher-1.jpg" alt="">
                                    </div>
                                    <div class="course__instructor-content">
                                       <h3>Dami</h3>
                                       <p>Associate</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <!-- CurriculumTab start -->
                     <NewCurriculumTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
                     <ReviewTab/>
                     <!-- CurriculumTab end -->

                     <!-- CurriculumTab start -->
                     <MemberTab/>
                     <!-- CurriculumTab end -->
                   
                     <div class="course__share">
                        <h3>Share :</h3>
                        <ul>
                           <li>
                              <a href="#" class="fb" ><i class="fab fa-facebook-f"></i>
                           </a></li>
                           <li>
                              <a href="#" class="tw" ><i class="fab fa-twitter"></i>
                           </a></li>
                           <li>
                              <a href="#" class="pin" ><i class="fab fa-pinterest-p"></i>
                           </a></li>
                        </ul>
                     </div>
                  </div>
               </div>
               <div class="course__related">
                  <div class="row">
                     <div class="col-xxl-12">
                        <div class="section__title-wrapper mb-40">
                           <h2 class="section__title">Related <span class="yellow-bg yellow-bg-big">Course<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                           <p>You don't have to struggle alone, you've got our assistance and help.</p>
                        </div>
                     </div>
                  </div>
                  <!-- slider courses start-->
                  <SliderCourses/>
                  <!-- slider courses end-->
               </div>
            </div>
         </div>

       <!-- course side bar start -->
       <CourseSidebar/>
       <!-- course side bar end -->

      </div>
   </div>
</section>
</template>

<script>
import SliderCourses from './SliderCourses.vue';
import CourseSidebar from './CourseSidebarCopy.vue';
import NewCurriculumTab from './NewCurriculumTab.vue';
import ReviewTab from './ReviewTab.vue';
import MemberTab from './MemberTab.vue';

export default {
   name:'CourseDetailsArea',
   components:{
      SliderCourses,
      CourseSidebar,
      NewCurriculumTab,
      ReviewTab,
      MemberTab,
   }
};
</script>

