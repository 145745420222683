<template>
    <div v-for="dbs in dbsItems" :key="dbs.id" class="col-xxl-12">
      <div class="course__item white-bg mb-30 fix">
        <div class="row gx-0">
          <div class="col-xxl-4 col-xl-4 col-lg-4">
            <div class="course__thumb course__thumb-list w-img p-relative fix">
              <router-link :to="`/course-details/${dbs.id}`">
                <img :src="dbs.listImg" alt="">
              </router-link>
              <div class="course__tag">
                <a href="#" :class="dbs.color">{{dbs.category}}</a>
              </div>
            </div>
          </div>
          <div class="col-xxl-8 col-xl-8 col-lg-8">
            <div class="course__right">
              <div class="course__content course__content-3">
                <h3 class="course__title course__title-3">
                  <router-link :to="dbs.path">{{dbs.title}}</router-link>
                </h3>
                <div class="course__summary" v-html="dbs.description">
                  
                </div>
              </div>
              <div class="course__more course__more-2 d-flex justify-content-between align-items-center">
                <div class="course__status d-flex align-items-center">
                  <span :class="dbs.color">£{{dbs.price}}</span>
                </div>
                <div class="course__btn">
                  <router-link :to="dbs.path" class="link-btn">
                    Contact us
                    <i class="far fa-arrow-right"></i>
                    <i class="far fa-arrow-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import DBSMixin from '../../mixins/dbsItemsMixin';
  export default {
    name: 'DBSItems',
    mixins: [DBSMixin]
  };
  </script>
  