<template>
   <div class="row" v-if="topCourses.length">
     <div class="col-xxl-12">
       <div class="course__slider swiper-container pb-60">
         <swiper
           ref="mySwiper"
           class="pb-50"
           :space-between="25"
           :slides-per-view="2"
           :modules="modules"
           :pagination="{clickable: true}"
           :loop="true"
           :breakpoints="breakpoints"
         >
           <swiper-slide v-for="course in topCourses" :key="course.id">
             <!-- Ensure course is defined before rendering -->
             <div v-if="course" class="course__item course__item-3 swiper-slide white-bg mb-30 fix">
               <div class="course__thumb w-img p-relative fix">
                 <router-link :to="`/course-details/${course.id}`">
                   <img :src="getCourseImagePath(course.image_path)" alt="">
                 </router-link>
                 <div class="course__tag">
                   <a href="#" :class="course.color">{{course.category}}</a>
                 </div>
               </div>
               <div class="course__content">
                 <div class="course__meta d-flex align-items-center justify-content-between">
                   <div class="course__lesson">
                     <span><i class="far fa-book-alt"></i>{{course.lesson}} Lesson</span>
                   </div>
                   <div class="course__rating">
                     <span><i class="icon_star"></i>{{course.rating}} (44)</span>
                   </div>
                 </div>
                 <h3 class="course__title">
                   <router-link :to="`/course-details/${course.id}`">{{course.title}}</router-link>
                 </h3>
               </div>
               <div class="course__more d-flex justify-content-between align-items-center">
                 <div class="course__status d-flex align-items-center">
                   <span :class="course.color">£{{course.price}}</span>
                   <span class="old-price">£{{course.oldPrice}}</span>
                 </div>
                 <div class="course__btn">
                   <router-link :to="`/course-details/${course.id}`" class="link-btn">
                     Know Details
                     <i class="far fa-arrow-right"></i>
                     <i class="far fa-arrow-right"></i>
                   </router-link>
                 </div>
               </div>
             </div>
           </swiper-slide>
         </swiper>
         <div class="swiper-pagination"></div>
       </div>
     </div>
   </div>
   <div v-else>
     <p>No courses available at the moment.</p>
   </div>
 </template>
 
 <script>
 import axios from 'axios';
 import { Swiper, SwiperSlide } from "swiper/vue";
 import { Pagination } from "swiper";
 
 export default {
   name: 'SliderCourses',
   components: {
     Swiper,
     SwiperSlide,
   },
   data() {
     return {
       breakpoints: {
         550: { slidesPerView: 1 },
         768: { slidesPerView: 2 },
         992: { slidesPerView: 2 },
       },
       courseItems: [],
       isLoading: true, // Loading state
       error: null // Error state
     };
   },
   computed: {
     topCourses() {
       return this.courseItems.slice(0, 4);
     }
   },
   methods: {
     async fetchCourses() {
       try {
         const apiUrl = process.env.VUE_APP_API_BASE_URL;  // Get the API URL from the environment variable
         const response = await axios.get(`${apiUrl}/courses`);
         this.courseItems = response.data.courses;
       } catch (error) {
         this.error = 'Failed to load courses. Please try again later.';
         console.error('Error fetching courses:', error.response ? error.response.data : error.message);
       } finally {
         this.isLoading = false;
       }
     },
     getCourseImagePath(imagePath) {
        const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL;  // Get the storage base URL from the environment variable
        return `${storageUrl}/${imagePath}`;
     },
   },
   created() {
     this.fetchCourses();
   },
   beforeUnmount() {
     // Ensure proper cleanup of Swiper instance
     if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
       this.$refs.mySwiper.swiper.destroy(true, true);
     }
   },
   setup() {
     return {
       modules: [Pagination],
     };
   },
 };
 </script>
 