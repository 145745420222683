export default {
    data() {
        return {
           blogItems:  [
               {
                   id: 1,
                   blogImg: require(`@/assets/img/blog/blog-1.jpg`),
                //    listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   title: 'FountainLife Training: Nurturing Bright Futures through Early Childhood Education',
                   authorImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   authorName: 'Marlen',
                   category: 'Childcare',
                   date:'Jan 30, 2024',
                   color:'green'
               },
                    
           ]
        }
    }
}