<template>
  <EventDetailsTitle/>
   <section class="event__area pb-110">
    <div class="container">
        <div class="row">
            <div class="col-xxl-8 col-xl-8 col-lg-8">
                <div class="events__wrapper">
                <div class="events__thumb mb-35 w-img">
                    <img src="../../assets/img/events/event-1.jpg" alt="">
                </div>
                <div class="events__details mb-35">
                    <h3>Unlocking the Digital Realm: A Comprehensive Learning Event with South London's Trusted Web Hosting Nominee of the year 2024</h3>
                    <p>In the fast-evolving digital landscape, staying ahead of the curve is crucial for anyone involved in the realms of web hosting, web development, and project management. To empower aspiring professionals, South London's most trusted web hosting company is hosting a transformative event. The nominee, recognized for their expertise, will guide participants through three immersive sessions focused on affiliate marketing, web development with trending frameworks, and project management using agile principles.

                    Session 1: Mastering Affiliate Marketing in Web Hosting

                    The first session of this event is dedicated to unlocking the potential of affiliate marketing within the web hosting industry. Participants will have the opportunity to learn from the nominee, who has a proven track record in this field. The session will cover strategies to leverage affiliate marketing effectively, exploring how partnerships and collaborations can drive success in the competitive hosting landscape.

                    Session 2: Web Development Unleashed – No Coding Knowledge Required

                    In the second session, participants will delve into the world of web development, even if they have no prior coding knowledge. The nominee, renowned for their proficiency in web development, will introduce trending frameworks that are shaping the digital landscape. Through hands-on demonstrations and practical insights, attendees will gain a comprehensive understanding of creating dynamic and visually appealing websites without the need for extensive coding expertise.

                    Session 3: Agile Project Management Principles

                    The final session focuses on project management using agile principles, a methodology gaining widespread recognition for its effectiveness in the tech industry. The nominee will share insights into applying agile principles to web development projects, ensuring efficiency, adaptability, and client satisfaction. Participants will learn how to streamline workflows, enhance collaboration, and deliver successful projects within dynamic and fast-paced environments.

                    Networking Opportunities:

                    Beyond the educational content, the event will provide ample networking opportunities. Attendees can connect with like-minded individuals, industry professionals, and the nominee themselves. Building a strong professional network is a crucial aspect of success in the tech industry, and this event offers the perfect environment to foster meaningful connections.

                    Conclusion:

                    South London's most trusted web hosting company is committed to fostering knowledge-sharing and skill development within the tech community. The nominee's expertise in affiliate marketing, web development, and agile project management makes this event a unique and valuable opportunity for professionals and enthusiasts alike. By the end of the sessions, participants will not only have acquired valuable insights but also gained practical skills to excel in the rapidly evolving digital landscape.</p>
                </div>
                <div class="events__allow mb-40">
                    <h3>This event is open to participants and:</h3>
                    <ul>
                        <li><i class="fal fa-check"></i> Attendees looking to expand their professional network</li>
                        <li><i class="fal fa-check"></i> Those running or planning to start their web-related businesses </li>
                        <li><i class="fal fa-check"></i> Students studying web development, IT, or related fields</li>
                    </ul>
                </div>
                <div class="events__tag">
                    <span><i class="fal fa-tag"></i></span>
                    <a href="#">Web Development,  </a>
                    <a href="#">Project management,</a>
                    <a href="#">Affiliate Marketing</a>
                </div>
                </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4">
                <div class="events__sidebar pl-70">
                <div class="events__sidebar-widget white-bg mb-20">
                    <div class="events__sidebar-shape">
                        <img class="events-sidebar-img-2" src="../../assets/img/events/event-shape-2.png" alt="">
                        <img class="events-sidebar-img-3" src="../../assets/img/events/event-shape-3.png" alt="">
                    </div>
                    <div class="events__info">
                        <div class="events__info-meta mb-25 d-flex align-items-center justify-content-between">
                            <div class="events__info-price">
                            <h5>£25.00.<span>00</span> </h5>
                            <h5 class="old-price">£100.00</h5>
                            </div>
                            <div class="events__info-discount">
                            <span>75% OFF</span>
                            </div>
                        </div>
                        <div class="events__info-content mb-35">
                            <ul>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>End: </span> July 4, 2024 2:30 pm</h5>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>Time:</span>  10:00 AM-02:30 PM</h5>
                                </div>
                            </li>
                            <li class="d-flex align-items-center">
                                <div class="events__info-icon">
                                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
                                        <path class="st0" d="M2,6l6-4.7L14,6v7.3c0,0.7-0.6,1.3-1.3,1.3H3.3c-0.7,0-1.3-0.6-1.3-1.3V6z"/>
                                        <polyline class="st0" points="6,14.7 6,8 10,8 10,14.7 "/>
                                    </svg>
                                </div>
                                <div class="events__info-item">
                                    <h5><span>Venue: </span> New Cross Gate, London</h5>
                                </div>
                            </li>
                            </ul>
                        </div>
                        <div class="events__join-btn">
                            <router-link to="/contact" class="e-btn e-btn-7 w-100">Enroll <i class="far fa-arrow-right"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="events__sidebar-widget white-bg">
                    <div class="events__sponsor">
                        <h3 class="events__sponsor-title">Sponsors</h3>
                        <div class="events__sponsor-thumb mb-35">
                            <img src="../../assets/img/events/sponsor-logo.png" alt="">
                        </div>
                        <div class="events__sponsor-info">
                            <h3>Stephen N.B.</h3>
                            <h4>Email: <span>info@niniwebsites.com</span></h4>
                            <div class="events__social d-xl-flex align-items-center">
                            <h4>Share:</h4>
                            <ul>
                                <li><a href="#" class="fb" >
                                    <i class="fab fa-facebook-f"></i>
                                </a></li>
                                <li><a href="#" class="tw" >
                                    <i class="fab fa-twitter"></i>
                                </a></li>
                                <li><a href="#" class="pin" >
                                    <i class="fab fa-pinterest-p"></i>
                                </a></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
import EventDetailsTitle from './EventDetailsTitle.vue';

export default {
    name:'EventDetailsArea',
    components:{
        EventDetailsTitle,
    }
}
</script>

