<template>
    <!--Dashbord Student -->
    <div class="page-content">
        <div class="container">
            <div class="row">
                
                <!-- sidebar -->
                <div class="col-xl-3 col-md-4 theiaStickySidebar">
                    <div class="settings-widget dash-profile mb-3">
                        <div class="settings-menu p-0">
                            <div class="profile-bg">
                                <h5>Beginner</h5>
                                <img src="../../assets/assets/img/profile-bg.jpg" alt="">
                                <div class="profile-img">
                                    <a href="student-profile.html"><img src="../../assets/assets/img/user/user11.jpg" alt=""></a>
                                </div>
                            </div>
                            <div class="profile-group">
                                <div class="profile-name text-center">
                                    <h4><a href="student-profile.html">Rolands R</a></h4>
                                    <p>Student</p>
                                </div>
                                <div class="go-dashboard text-center">
                                    <router-link to="/dashboard" class="btn btn-primary">Go to Dashboard</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings-widget account-settings">
                        <div class="settings-menu">
                            <h3>ACCOUNT SETTINGS</h3>
                            <ul>
                                <li class="nav-item ">
                                    <router-link to="/student-setting" class="nav-link">
                                        <i class="feather-settings"></i> Edit Profile  
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/student-security" class="nav-link">
                                        <i class="feather-user"></i> Security   
                                    </router-link>
                                </li>
                                <li class="nav-item active">
                                    <router-link to="/student-delete" class="nav-link">
                                        <i class="feather-trash-2"></i> Delete Profile  
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <a href="login.html" class="nav-link">
                                        <i class="feather-power"></i> Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>					
                </div>
                <!-- /sidebar -->
                
                <!-- Profile Details -->
                <div class="col-xl-9 col-md-8">	
                    <div class="settings-widget profile-details">
                        <div class="settings-menu p-0">
                            <div class="profile-heading">
                                <h3>Delete your account</h3>
                                <p>Delete or Close your account permanently.</p>
                            </div>
                            <div class="checkout-form personal-address">
                                <div class="personal-info-head">
                                    <h4>Warning</h4>
                                    <p>If you close your account, you will be unsubscribed from all your 0 courses, and will lose access forever.</p>
                                </div>
                                <div class="un-subscribe p-0">
                                    <button @click="confirmDelete" class="btn btn-danger">Delete My Account</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>	
                <!-- Profile Details -->
                
            </div>
        </div>
    </div>	
    <!-- /Dashbord Student -->
</template>
<script>
import axios from 'axios';

export default {
    name: 'StudentDelete',
    methods: {
             
        confirmDelete() {
            const confirmed = confirm('Are you sure you want to delete your account? This action cannot be undone.');
            if (confirmed) {
                this.deleteAccount();
            }
        },
        
        async deleteAccount() {
            try {
                await axios.delete('/api/delete-account');
                // Clear token and user data from Vuex
                await this.$store.dispatch('clearToken');

                localStorage.removeItem('token'); // Clear token from local storage
                localStorage.removeItem('refresh_token'); // Clear refresh token from local storage
                localStorage.removeItem('user'); // Clear user data from local storage
                
                alert('Your account has been deleted successfully.');
                // Redirect to login page or another appropriate page
                this.$router.push('/login');
            } catch (error) {
                console.error('Error deleting account:', error);
                alert('Failed to delete account. Please try again.');
            }
        }
    }
};
</script>