export default {
    data() {
        return {
           dbsItems:  [
               {
                   id: 1,
                   courseImage: require(`@/assets/img/course/course-1.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   lesson:'43',
                   title: 'Basic disclosure',
                   description: 'Provides a summary of an individual\'s criminal record, highlighting any unspent convictions and conditional cautions. It\'s a crucial document for employers and organizations seeking to ensure the suitability of individuals for specific roles, particularly those involving work with vulnerable populations such as children or adults.</br><b>NOTE</b>: For Nannies and Childminders you will have to undergo an Enhanced DBS check before applying with ofsted.',
                   path:'/contact',
                   rating: '4.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Fundamentals',
                   price:'45.00',
                   oldPrice:'69.00'
               },
               {
                   id: 2,
                   courseImage: require(`@/assets/img/course/course-2.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                   lesson:'72',
                   title: 'Standard Disclosure',
                   description: 'Provides a comprehensive overview of an \'s criminal record, offering insights beyond what\'s included in a Basic DBS check. This disclosure is particularly crucial for employers and organizations involved in sensitive sectors, such as healthcare, finance, and education. It encompasses details of both spent and unspent convictions, cautions, reprimands, and final warnings, providing a thorough background assessment for informed decision-making in recruitment and safeguarding processes.',
                   path:'/contact',
                   rating: '4.0',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Fundamentals',
                   price:'45.00',
                   oldPrice:'20.00',
                   color:'sky-blue'
               },
               {
                   id: 3,
                   courseImage: require(`@/assets/img/course/course-3.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_3.jpeg`),
                   lesson:'35',
                   title: 'Enhanced Disclosure',
                   description: 'The Enhanced DBS disclosure is the highest level of background check in the UK, offering detailed insights into an individual\'s criminal history. It includes information beyond Standard and Basic disclosures, incorporating data from local police forces. This check reveals spent and unspent convictions, cautions, and additional relevant information. Essential for organizations working with vulnerable groups, the Enhanced DBS disclosure enables informed decisions in safeguarding and ensuring safety ',
                   path:'/contact',
                   rating: '4.3',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Core skills',
                   price:'70.00',
                   oldPrice:'70.00',
                   color:'green'
               },
               {
                   id: 4,
                   courseImage: require(`@/assets/img/course/course-4.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_4.jpeg`),
                   lesson:'60',
                   title: 'Volunteer Disclosure',
                   description: 'Provides a comprehensive overview of an individual\'s criminal record, tailored specifically for volunteers. It includes details of spent and unspent convictions, cautions, reprimands, and final warnings, ensuring transparency and safety within volunteer programs. This disclosure is essential for organizations relying on volunteers, helping them make informed decisions to protect their communities and maintain trust.',
                   path:'/contact',
                   rating: '3.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'First aid',
                   price:'13.00',
                   oldPrice:'20.00',
                   color:'blue'
               },
               
           ]
        }
    }
}