import { createApp } from 'vue';
import App from './App.vue';

import './assets/assets/css/bootstrap.min.css';
import './assets/assets/plugins/fontawesome/css/fontawesome.min.css';
import './assets/assets/plugins/fontawesome/css/all.min.css';
import './assets/assets/plugins/feather/feather.css';
import './assets/assets/plugins/select2/css/select2.min.css';
import './assets/assets/css/style.css';

import 'bootstrap/scss/bootstrap.scss';
import router from './router';
import 'swiper/css/bundle';
import './assets/css/fontAwesome5Pro.css';
import 'bootstrap';
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import './assets/scss/main.scss';


import './axios';
import store from './store/store';

let app = createApp(App);
app.use(router);
app.use(store); // Use Vuex store
app.use(BootstrapVue3);
app.mount('#app');
