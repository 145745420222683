<template>
    <Header/>
    <StudentModule/>
    <AppointmentScheduler/>
    <Footer footerPadding="true" />
 </template>
 
 <script>
 import Header from '../components/HomeThree/Header.vue';
 import AppointmentScheduler from '../components/NewDashboard/Appointment.vue';
 import StudentModule from '../components/NewDashboard/Student.vue';
 import Footer from '../components/Home/Footer.vue';
 
 export default {
    name:'NewDashboardAppointment',
    components:{
       Header,
       StudentModule,
       AppointmentScheduler,
       Footer,
    }
 };
 </script>

 