<template>
<section class="signup__area po-rel-z1 pt-100 pb-145">
   <div class="sign__shape">
      <img class="man-1" src="../../assets/img/icon/sign/man-3.png" alt="">
      <img class="man-2 man-22" src="../../assets/img/icon/sign/man-2.png" alt="">
      <img class="circle" src="../../assets/img/icon/sign/circle.png" alt="">
      <img class="zigzag" src="../../assets/img/icon/sign/zigzag.png" alt="">
      <img class="dot" src="../../assets/img/icon/sign/dot.png" alt="">
      <img class="bg" src="../../assets/img/icon/sign/sign-up.png" alt="">
      <img class="flower" src="../../assets/img/icon/sign/flower.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
            <div class="section__title-wrapper text-center mb-55">
               <h2 class="section__title">Create a free <br>  Account</h2>
               <p>I'm a subhead that goes with a story.</p>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
            <div class="sign__wrapper white-bg">
               <div class="sign__form">
                  <form @submit.prevent="register">
                     <div class="sign__input-wrapper mb-25">
                        <h5>Full Name</h5>
                        <div class="sign__input">
                           <input type="text" v-model="name" placeholder="Full name">
                           <i class="fal fa-user"></i>
                           <span v-if="errors.username" class="error-message">{{ errors.username }}</span>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-25">
                        <h5>Work email</h5>
                        <div class="sign__input">
                           <input type="text" v-model="email" placeholder="e-mail address">
                           <i class="fal fa-envelope"></i>
                        </div>
                        <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
                     </div>
                     <div class="sign__input-wrapper mb-25">
                        <h5>Password</h5>
                        <div class="sign__input">
                           <input type="text" v-model="password" placeholder="Password">
                           <i class="fal fa-lock"></i>
                        </div>
                        <span v-if="errors.password" class="error-message">{{ errors.password }}</span>
                     </div>
                     <div class="sign__input-wrapper mb-10">
                        <h5>Re-Password</h5>
                        <div class="sign__input">
                           <input type="text" v-model="re_password" placeholder="Re-Password">
                           <i class="fal fa-lock"></i>
                        </div>
                        <span v-if="errors.re_password" class="error-message">{{ errors.re_password }}</span>
                     </div>
                     <div class="sign__action d-flex justify-content-between mb-30">
                        <div class="sign__agree d-flex align-items-center">
                           <input class="m-check-input" type="checkbox" id="m-agree" v-model="agree">
                           <label class="m-check-label" for="m-agree">
                              I agree to the <a href="#">Terms & Conditions</a>
                           </label>
                        </div>
                        <span v-if="errors.agree" class="error-message">{{ errors.agree }}</span>
                     </div>
                     <button class="e-btn w-100" :disabled="!agree"> <span></span> Sign Up</button>
                     <div class="sign__new text-center mt-20">
                        <p>Already in Fountainlife Training ? <router-link to="/login"> Sign In</router-link></p>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
import axios from 'axios';

export default {
  name: "SignUpArea",
  data() {
    return {
      name: '',
      email: '',
      password: '',
      re_password: '',
      agree: false, // Track whether checkbox is checked or not
      errors: {}, // Add errors object to track form validation errors
    };
  },
  methods: {
   async register() {
   // Reset errors object on each form submission
   this.errors = {};

   // Check if passwords match
   if (this.password !== this.re_password) {
      this.errors.re_password = "Passwords do not match";
      console.log('API Response:', this.errors.re_password);
      return; // Prevent further execution if passwords don't match
   }

   // Submit registration form after passing validation
   try {

      // Step 1: Request CSRF token
      // await axios.get('/sanctum/csrf-cookie');
      // Step 2: Retrieve the CSRF token
      //const csrfToken = await this.getCsrfToken();

      const requestData = {
         name: this.name,
         email: this.email,
         password: this.password,
         password_confirmation: this.re_password,
      };

      //console.log('Csrf token sent: ', csrfToken);

      // Send registration request to the server
      // Step 2: Include CSRF token in the request headers
      // const response = await axios.post('/register', requestData, {
      //     headers: {
      //       'X-XSRF-TOKEN': csrfToken, // Include CSRF token in the headers
      //     },
      //   });
      //   console.log('Csrf token sent: ', csrfToken);
      const response = await axios.post('/register', requestData);

      // Extract the token from the response data
      // const token = response.data.token;

      // Save the token in local storage or Vuex store
      // You can use Vuex store if you have it configured
      // For simplicity, let's assume using local storage
      // localStorage.setItem('token', token);

      // Optionally, you can redirect the user to another page after successful registration
      this.$router.push('/login');

      // console.log('Registration successful. Token:', token);
      console.log('API Response:', response.data);
   } catch (error) {
      // Check if the error is from the server response
      if (error.response && error.response.data) {
         // Extract error data from the server response
         const responseData = error.response.data;
         
         // Check if there are specific error messages in the response data
         if (responseData.errors) {
         // Update the errors object with the error messages from the server
         this.errors = responseData.errors;
         console.error('Error submitting form:', responseData.errors);
         } else {
         // If there are no specific error messages, log the general error message
         console.error('Error submitting form:', responseData.message);
         }
      } else {
         // If the error is not from the server response, log the error message
         console.error('Error submitting form:', error.message);
      }
   }
   },
   
   async  getCsrfToken() {
  try {
    // Make a request to /sanctum/csrf-cookie to set the CSRF token in cookies
    const url = process.env.VUE_APP_BASE_URL;  // Get the API URL from the environment variable
    await axios.get(`${url}/sanctum/csrf-cookie`, { withCredentials: true });
    
    // Retrieve the CSRF token from the cookie
    const name = 'XSRF-TOKEN=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookies = decodedCookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.startsWith(name)) {
        return cookie.substring(name.length);
      }
    }
    throw new Error('CSRF token not found');
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
    throw error;
  }
},
  },
};
</script>

