<template>
  <div class="container mt-4">
    <h2 class="mb-4">Manage Availability</h2>
    <form @submit.prevent="submitForm">
      <!-- Course Selection -->
      <div class="form-group mb-3">
        <label for="course_id">Select Course:</label>
        <!-- Show loading message while fetching courses -->
        <div v-if="loading" class="text-muted">Loading courses...</div>
        <div v-else>
          <select v-model="courseId" id="course_id" class="form-control" required>
            <option disabled value="">Please select a course</option>
            <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.title }}</option>
          </select>
        </div>
      </div>

      <!-- Availability Fields -->
      <div v-for="(availability, index) in availabilities" :key="index" class="mb-3">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label :for="'available_time_' + index">Available Time:</label>
              <input
                :id="'available_time_' + index"
                type="datetime-local"
                v-model="availability.available_time"
                class="form-control"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label :for="'is_booked_' + index">Is Booked:</label>
              <input
                :id="'is_booked_' + index"
                type="checkbox"
                v-model="availability.is_booked"
                class="form-check-input"
              />
            </div>
          </div>
        </div>
        <button @click="removeAvailability(index)" type="button" class="btn btn-danger mt-2">
          Remove
        </button>
      </div>

      <button @click="addAvailability" type="button" class="btn btn-primary mb-3">
        Add Availability
      </button>
      <button type="submit" class="btn btn-success">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'InstructorAvailability',
  data() {
    return {
      availabilities: [{ available_time: '', is_booked: false }],
      courseId: '', // Initially empty; instructor must select a course
      courses: [], // List of courses (to be populated from API)
      loading: true, // Track whether courses are loading
    };
  },
  mounted() {
    // Fetch the list of courses when the component is mounted
    this.fetchCourses();
  },
  methods: {
    async fetchCourses() {
      try {
        const response = await axios.get('/api/courses');
        this.courses = response.data.courses;
      } catch (error) {
        console.error('Error fetching courses:', error);
        alert('Failed to load courses.');
      } finally {
        this.loading = false; // Set loading to false once the request completes
      }
    },
    addAvailability() {
      this.availabilities.push({ available_time: '', is_booked: false });
    },
    removeAvailability(index) {
      this.availabilities.splice(index, 1);
    },
    async submitForm() {
      try {
        // Ensure courseId is selected
        if (!this.courseId) {
          alert('Please select a course.');
          return;
        }

        // Convert datetime-local to the required format (Y-m-d H:i:s)
        const formattedAvailabilities = this.availabilities.map(avail => ({
          ...avail,
          available_time: this.formatDate(avail.available_time),
        }));

        const payload = {
          course_id: this.courseId,
          availabilities: formattedAvailabilities,
        };

        console.log('Submitting payload:', payload);

        const response = await axios.post('/api/availabilities', payload);
        alert(response.data.message);
      } catch (error) {
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
        alert('An error occurred while saving availabilities.');
      }
    },
    formatDate(dateStr) {
      // Convert datetime-local to Y-m-d H:i:s
      return new Date(dateStr).toISOString().slice(0, 19).replace('T', ' ');
    },
  },
};
</script>

<style scoped>
/* Optional: Add custom styles if needed */
</style>
