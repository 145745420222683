<template>
   <section class="course-area pt-100 pb-100">
     <div class="container">
       <!-- Loading State -->
       <div v-if="isLoading" class="text-center">
         <p>Loading courses...</p>
       </div>
       <!-- Error State -->
       <div v-else>
         <div v-if="error" class="alert alert-danger">
           <p>{{ error }}</p>
         </div>
         <!-- Course Content -->
         <div v-else>
           <div class="row">
             <div v-for="course in courseItems" :key="course.id" class="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
               <div class="course__item white-bg mb-30 fix">
                 <div class="course__thumb w-img p-relative fix">
                   <router-link :to="`/course-details/${course.id}`">
                     <img :src="getCourseImagePath(course.image_path)" alt="">
                   </router-link>
                   <div class="course__tag">
                     <a href="#" :class="course.color">{{course.category}}</a>
                   </div>
                 </div>
                 <div class="course__content">
                   <div class="course__meta d-flex align-items-center justify-content-between">
                     <div class="course__lesson">
                       <span><i class="far fa-book-alt"></i>{{course.lesson}} Lesson</span>
                     </div>
                     <div class="course__rating">
                       <span><i class="icon_star"></i>{{course.rating}} (44)</span>
                     </div>
                   </div>
                   <h3 class="course__title">
                     <router-link :to="`/course-details/${course.id}`">
                       {{course.title}}
                     </router-link>
                   </h3>
                 </div>
                 <div class="course__more d-flex justify-content-between align-items-center">
                   <div class="course__status d-flex align-items-center">
                     <span :class="course.color">£{{course.price}}</span>
                     <span class="old-price">£{{course.oldPrice}}</span>
                   </div>
                   <div class="course__btn">
                     <router-link :to="`/course-details/${course.id}`" class="link-btn">
                       Know Details
                       <i class="far fa-arrow-right"></i>
                       <i class="far fa-arrow-right"></i>
                     </router-link>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </section>
</template>


<script>
// import CourseMixin from '../../mixins/courseItemsMixin';

// export default {
//    name:'GridTab',
//    mixins:[CourseMixin]
// };

import axios from 'axios';

export default {
   name: 'GridTab',
   data() {
      return {
         courseItems: [],
         isLoading: true, // Loading state
         error: null,     // Error state
      };
   },
   methods: {
      async fetchCourses() {
         try {
            const apiUrl = process.env.VUE_APP_API_BASE_URL;  // Get the API URL from the environment variable
            const response = await axios.get(`${apiUrl}/courses`);
            console.log('ResponseD :', response);
            this.courseItems = response.data.courses;
         } catch (error) {
            this.error = 'Failed to load courses. Please try again later.';
            console.error('Error fetching courses:', error.response ? error.response.data : error.message);
         } finally {
            this.isLoading = false;
         }
      },
      getCourseImagePath(imagePath) {
        const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL;  // Get the storage base URL from the environment variable
        return `${storageUrl}/${imagePath}`;
      }
   },
   created() {
      this.fetchCourses();
   },
};

</script>
