<template>
    <section class="course-content">
        <div class="container">
            <div class="student-widget">
                <div class="student-widget-group">
                    <div class="row">
                        <div class="col-lg-12">

                            <!-- Filter -->
                            <div class="showing-list">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="show-filter choose-search-blk">
                                            <form action="#">
                                                <div class="mycourse-student align-items-center">
                                                    <div class="student-search">
                                                        <div class="search-group">
                                                            <i class="feather-search"></i>
                                                            <input type="text" class="form-control"
                                                                placeholder="Search our courses">
                                                        </div>
                                                    </div>
                                                    <div class="student-filter">
                                                        <div class="input-block select-form mb-0">
                                                            <select class="form-select select" name="sellist1">
                                                                <option>Newly published</option>
                                                                <option>Angular</option>
                                                                <option>React</option>
                                                                <option>Node</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Filter -->

                            <!-- Loading State -->
                            <div v-if="loading" class="text-center">
                                <p>Loading enrolled courses...</p>
                            </div>

                            <!-- Error State -->
                            <div v-else>
                                <div v-if="error" class="alert alert-danger">
                                    <p>{{ error }}</p>
                                </div>

                                <!-- Render Enrolled Courses -->
                                <div v-else>
                                    <div class="row">
                                        <div class="col-lg-3" v-for="enrollment in enrolledCourses" :key="enrollment.id">
                                            <div class="course-box course-design d-flex">
                                                <div class="product">
                                                    <div class="product-img">
                                                        <a :href="`course-details/${enrollment.course.id}`">
                                                            <img class="img-fluid" :src="getCourseImagePath(enrollment.course.image_path)" alt="">
                                                        </a>
                                                    </div>
                                                    <div class="product-content">
                                                        <h3 class="title">
                                                            <a :href="`course-details/${enrollment.course.id}`">{{ enrollment.course.title }}</a>
                                                        </h3>
                                                        <div class="start-leason d-flex align-items-center">
                                                            <router-link :to="`/course-lesson/${enrollment.course.id}/appointments`" class="btn btn-primary">Book Session</router-link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Placeholder for no courses -->
                                        <div v-if="enrolledCourses.length === 0" class="col-lg-12">
                                            <p>No enrolled courses found.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Render Enrolled Courses -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import axios from 'axios';

export default {
    name: 'CourseEnrollment',
    data() {
        return {
            enrolledCourses: [], // Initialize enrolledCourses array
            loading: true,       // Loading state
            error: null,         // Error state
        };
    },
    methods: {
        async fetchEnrolledCourses() {
            try {
                // Fetch enrolled courses data from the backend API
                const response = await axios.get('/api/enrolled-courses');
                this.enrolledCourses = response.data; // Assign fetched data to enrolledCourses array
                
                // Log course details for debugging (optional)
                this.enrolledCourses.forEach(item => {
                    const course = item.course;
                    console.log('Course Title:', course.title);
                    console.log('Course Description:', course.description);
                    console.log(this.$route);
                });
                
            } catch (error) {
                this.error = 'Failed to load enrolled courses. Please try again later.';
                console.error('Error fetching enrolled courses:', error.response ? error.response.data : error.message);
            } finally {
                this.loading = false; // Set loading to false after the data is fetched
            }
        },
        getCourseImagePath(imagePath) {
            const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL;
            return `${storageUrl}/${imagePath}`;
        }
    },
    mounted() {
        // Fetch enrolled courses data when the component is mounted
        this.fetchEnrolledCourses();
    }
};
</script>

