<template>
<!--Dashbord Student -->
    <div class="page-content">
        <div class="container">
            <div class="row">
                
                <!-- sidebar -->
                <div class="col-xl-3 col-md-4 theiaStickySidebar">
                    <div class="settings-widget dash-profile mb-3">
                        <div class="settings-menu p-0">
                            <div class="profile-bg">
                                <h5>Beginner</h5>
                                <img src="../../assets/assets/img/profile-bg.jpg" alt="">
                                <div class="profile-img">
                                    <a href="student-profile.html"><img :src="userProfileImageUrl" alt="Profile Image" class="img-fluid" /></a>
                                </div>
                            </div>
                            <div class="profile-group">
                                <div class="profile-name text-center">
                                    <h4><a href="student-profile.html">Rolands R</a></h4>
                                    <p>Student</p>
                                </div>
                                <div class="go-dashboard text-center">
                                    <router-link to="/dashboard" class="btn btn-primary">Go to Dashboard</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings-widget account-settings">
                        <div class="settings-menu">
                            <h3>ACCOUNT SETTINGS</h3>
                            <ul>
                                <li class="nav-item active">
                                    <router-link to="/student-setting" class="nav-link">
                                        <i class="feather-settings"></i> Edit Profile  
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link to="/student-security" class="nav-link">
                                        <i class="feather-user"></i> Security   
                                    </router-link>
                                </li>
                                <li class="nav-item ">
                                    <router-link to="/student-delete" class="nav-link">
                                        <i class="feather-trash-2"></i> Delete Profile  
                                    </router-link>
                                </li> 
                                <li class="nav-item">
                                    <a href="login.html" class="nav-link">
                                        <i class="feather-power"></i> Sign Out
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>					
                </div>
                <!-- /sidebar -->
                
                <!-- Profile Details -->
                <div class="col-xl-9 col-md-8">	
                    <div class="settings-widget profile-details">
                        <div class="settings-menu p-0">
                            <div class="profile-heading">
                                <h3>Profile Details</h3>
                                <p>You have full control to manage your own account setting.</p>
                            </div>
                            <div class="course-group mb-0 d-flex">
                                <div class="course-group-img d-flex align-items-center">
                                    <a href="student-profile.html"><img :src="userProfileImageUrl" alt="Profile Image" class="img-fluid" /></a>
                                    <div class="course-name">
                                        <h4><a href="student-profile.html">Your avatar</a></h4>
                                        <p>PNG or JPG no bigger than 800px wide and tall.</p>
                                    </div>
                                </div>
                                <div class="profile-share d-flex align-items-center justify-content-center">
                                    <div>
                                        <input type="file" ref="fileInput" @change="handleFileUpload" class="btn btn-success">
                                        <button @click="uploadImage" class="btn btn-success">Update</button>
                                        <a href="javascript:void(0);" class="btn btn-danger">Delete</a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="checkout-form personal-address add-course-info ">
                                <div class="personal-info-head">
                                    <h4>Personal Details</h4>
                                    <p>Edit your personal information and address.</p>
                                </div>
                                <form @submit.prevent="updateProfile">
                                <div class="row">
                                    <div class="col-lg-6">
                                    <div class="input-block">
                                        <label class="form-control-label">First Name</label>
                                        <input v-model="user.name" type="text" class="form-control" placeholder="Enter your first Name">
                                    </div>
                                    </div>
                                    <!-- (Other fields as needed) -->
                                    <div class="update-profile">
                                    <button type="submit" class="btn btn-primary">Update Profile</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>	
                <!-- Profile Details -->
                
            </div>
        </div>
    </div>	
    <!-- /Dashbord Student -->
</template>
<script>
import axios from 'axios';

export default {
  name: 'StudentSetting',
  data() {
    return {
      imageFile: null,
      user: {} // Initialize user object
    };
  },
  methods: {
    handleFileUpload(event) {
      this.imageFile = event.target.files[0];
    },
    async uploadImage() {
      await axios.get('/sanctum/csrf-cookie');
      const formData = new FormData();
      formData.append('photo', this.imageFile);

      try {
        const response = await axios.post('/upload-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
           // 'X-XSRF-TOKEN': this.getCsrfToken(), // Include CSRF token in the headers
          }
        });
        console.log('Image upload successful:', response.data);
      } catch (error) {
        console.error('Image upload failed:', error);
      }
    },
    getCsrfToken() {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    },
    async fetchUserData() {
      try {
        const response = await axios.get('/api/user');
        this.user = response.data;
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },
    async updateProfile() {
      try {
        await axios.put('/api/update', this.user);
        alert('Profile updated successfully');
      } catch (error) {
        console.error('Error updating profile:', error);
      }
    }
  },
  mounted() {
    this.fetchUserData();
  },
  computed: {
    userProfileImageUrl() {
      if (this.user && this.user.profile_photo_path) {
        const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL;  // Get the storage base URL from the environment variable
        return `${storageUrl}/profile_photos/${this.user.profile_photo_path}`;
      }
        const storageUrl = process.env.VUE_APP_STORAGE_BASE_URL;  // Get the storage base URL from the environment variable
        return `${storageUrl}/profile_photos/placeholder.jpg`;
    }
  }
};
</script>