import axios from 'axios';
import store from './store/store'; // Adjust the path based on your folder structure

// Utility function to get the CSRF token
// function getCsrfToken() {
//   const name = 'XSRF-TOKEN=';
//   const decodedCookie = decodeURIComponent(document.cookie);
//   const cookies = decodedCookie.split(';');
//   for (let i = 0; i < cookies.length; i++) {
//     let cookie = cookies[i].trim();
//     if (cookie.indexOf(name) === 0) {
//       return cookie.substring(name.length, cookie.length);
//     }
//   }
//   return '';
// }



axios.defaults.baseURL = 'https://api.fountainlifetraining.com';
// axios.defaults.baseURL = 'http://localhost:8000';
// Set default headers for Axios requests
axios.defaults.headers.common['Content-Type'] = 'application/json';

// Enable sending cookies and XSRF token with requests
axios.defaults.withCredentials = true;
//axios.defaults.withXSRFToken = true;

// Create an Axios instance
const instance = axios.create({
  baseURL: 'https://api.fountainlifetraining.com', // Set the base URL
  headers: {
    'Content-Type': 'application/json',
    //'X-XSRF-TOKEN': getCsrfToken(),
  },
  withCredentials: true // Enable sending cookies with requests
});

// Interceptor to add headers to each request
instance.interceptors.request.use(config => {
  // Add custom headers
  // Check if the user is authenticated
  if (store.getters.isAuthenticated) {
    // Get the JWT token from the Vuex store
    const token = store.state.auth.token;

    // Include the token in the Authorization header
    config.headers.Authorization = `Bearer ${token}`;
  } // Replace with your actual auth token
  //config.headers['X-XSRF-TOKEN'] = getCsrfToken(); // Include CSRF token header

  return config;
}, error => {
  return Promise.reject(error);
});

// Add a request interceptor to include the JWT token in the request headers
axios.interceptors.request.use(
  config => {
    // Check if the user is authenticated
    if (store.getters.isAuthenticated) {
      // Get the JWT token from the Vuex store
      const token = store.state.auth.token;

      // Include the token in the Authorization header
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 errors and refresh the token
axios.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = store.state.auth.refreshToken;
        const response = await axios.post('/api/refresh-token', { refresh_token: refreshToken });

        const { access_token, refresh_token } = response.data;
        store.commit('setToken', access_token);
        store.commit('setRefreshToken', refresh_token);

        axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;

        return axios(originalRequest);
      } catch (refreshError) {
        store.commit('clearToken');
        store.commit('clearUser');
        this.$router.push({ name: 'login' });
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);


// Export Axios instance for use in Vue components
export default axios;

