<template>
    <section class="course__area pt-120 pb-120">
       <div class="container">
          <div class="row">
             <div class="col-xxl-12">
                <div class="course__tab-conent">
                   <div class="tab-content" id="courseTabContent">
                      <div class="tab-pane fade show active" id="list" role="tabpanel" aria-labelledby="list-tab">
                         <div class="row">
                            <!-- GridTabItems  -->
                            <DBSItems/>
                            <!-- GridTabItems  -->
                         </div>
                      </div>
                    </div>
                </div>
             </div>
          </div>
    
          <!-- pagination start -->
          
          <!-- pagination end -->
    
       </div>
    </section>
    </template>
    
    <script>
    
    import DBSItems from '../DBS/DBSItems.vue';
  
    
    export default {
       name:'DBSservicesArea',
       components:{
  
          DBSItems,
       }
    };
    </script>
    
    