<template>
<section class="signup__area po-rel-z1 pt-100 pb-145">
   <div class="sign__shape">
      <img class="man-1" src="../../assets/img/icon/sign/man-1.png" alt="">
      <img class="man-2" src="../../assets/img/icon/sign/man-2.png" alt="">
      <img class="circle" src="../../assets/img/icon/sign/circle.png" alt="">
      <img class="zigzag" src="../../assets/img/icon/sign/zigzag.png" alt="">
      <img class="dot" src="../../assets/img/icon/sign/dot.png" alt="">
      <img class="bg" src="../../assets/img/icon/sign/sign-up.png" alt="">
   </div>
   <div class="container">
      <div class="row">
         <div class="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
            <div class="section__title-wrapper text-center mb-55">
               <h2 class="section__title">Sign in to <br>  recharge direct.</h2>
               <p>it you don't have an account you can <a href="#">Register here!</a></p>
            </div>
         </div>
      </div>

      <!-- Loading indicator -->
      <div v-if="loading" class="loading-overlay">
         <div class="loading-spinner"></div>
      </div>

      <div class="row">
         <div class="col-xxl-6 offset-xxl-3 col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
            <div class="sign__wrapper white-bg">
               <div class="sign__form">
                  <form @submit.prevent="login">
                     <div class="sign__input-wrapper mb-25">
                        <h5>Work email</h5>
                        <div class="sign__input">
                           <input type="text" v-model="email" placeholder="e-mail address">
                           <i class="fal fa-envelope"></i>
                           <span v-if="errors" class="error-message">{{ errors }}</span>
                        </div>
                     </div>
                     <div class="sign__input-wrapper mb-10">
                        <h5>Password</h5>
                        <div class="sign__input">
                           <input type="text" v-model="password" placeholder="Password">
                           <i class="fal fa-lock"></i>
                           <span v-if="errors" class="error-message">{{ errors }}</span>
                        </div>
                     </div>
                     <div class="sign__action d-sm-flex justify-content-between mb-30">
                        <div class="sign__agree d-flex align-items-center">
                           <input class="m-check-input" type="checkbox" v-model="agree" id="m-agree">
                           <label class="m-check-label" for="m-agree">Keep me signed in
                              </label>
                              <span v-if="errors" class="error-message">{{ errors }}</span>
                        </div>
                        <div class="sign__forgot">
                           <a href="#">Forgot your password?</a>
                        </div>
                     </div>
                     <button class="e-btn  w-100"> <span></span> Sign In</button>
                     <div class="sign__new text-center mt-20">
                        <p>New to Fountainlife training? <router-link to="/register">Sign Up</router-link></p>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
import axios from 'axios';

export default {
  name: "LoginArea",
  data() {
    return {
      email: '',
      password: '',
      agree: false,
      errors: {}, // Add errors object to track form validation errors
      loading: false, // Add loading state
    };
  },
  methods: {
   async login() {
      this.errors = {};
      this.loading = true;

      try {
         // Get CSRF token
         await axios.get('/sanctum/csrf-cookie');

         // Attempt to login
         const response = await axios.post('/login', {
            email: this.email,
            password: this.password,
         }, {
            headers: {
            'X-XSRF-TOKEN': this.getCsrfToken(),
            },
         });

         // Extract token and user data from response
         const { access_token, user } = response.data;

         // Store token and user data in Vuex store
         this.$store.dispatch('saveToken', { token: access_token, user });
         await this.$store.dispatch('fetchUser');

         // Retrieve the redirect path from Vuex
         const redirectTo = this.$store.getters['query/fullPath'] || '/dashboard';
         console.log('Redirecting to:', redirectTo); // Debugging line

         // Clear the redirect path from Vuex
         this.$store.dispatch('query/clearQuery');

         // Redirect to the desired path
         this.$router.push(redirectTo);

      } catch (error) {
         if (error.response && error.response.data && error.response.data.errors) {
            this.errors = error.response.data.errors;
         } else {
            console.error('Error logging in:', error);
         }
      } finally {
         this.loading = false;
      }
   },
    getCsrfToken() {
      const name = 'XSRF-TOKEN=';
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookies = decodedCookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(name) === 0) {
          return cookie.substring(name.length, cookie.length);
        }
      }
      return '';
    },
  },
};
</script>

<style>
/* Loading indicator styles */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #3498db; /* Change color as needed */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Adjust animation duration as needed */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>