<template>
   <section class="about__area pt-90 pb-150">
      <div class="container">
         <div class="row">
            <div class="col-xxl-5 offset-xxl-1 col-xl-6 col-lg-6">
               <div class="about__thumb-wrapper">
                  <div class="about__thumb ml-100">
                     <img src="../../assets/img/about/about.jpg" alt="">
                  </div>
                  <div class="about__banner mt--210">
                     <img src="../../assets/img/about/about-banner.jpg" alt="">
                  </div>
               </div>
            </div>
            <div class="col-xxl-6 col-xl-6 col-lg-6">
               <div class="about__content pl-70 pr-60 pt-25">
                  <div class="section__title-wrapper mb-25">
                     <h2 class="section__title">Elevate Your <br><span class="yellow-bg-big">Career<img src="../../assets/img/shape/yellow-bg-2.png" alt="">
                     </span>with Fountainlife Training </h2>
                     <p>At Fountainlife, we are dedicated to equipping you with the essential qualifications to become a certified Ofsted registered nanny and childminder. Additionally, we offer a diverse selection of core training courses. Our mission is to continually expand our repertoire of courses focused on professional domestic training. Explore the possibilities and enhance your career with Fountainlife Training.</p>
                  </div>
                  <div class="about__list mb-35">
                     <ul>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Upskill your organization.</li>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Access various online courses</li>
                        <li class="d-flex align-items-center"> <i class="icon_check"></i> Learn the latest skills</li>
                     </ul>
                  </div>
                  <router-link to="/register" class="e-btn e-btn-border">Register now</router-link>
               </div>
            </div>
         </div>
      </div>
   </section>
</template>

<script>
export default {
   name:'AboutArea'
};
</script>
