export default {
    data() {
        return {
           courseItems:  [
               {
                   id: 1,
                   courseImage: require(`@/assets/img/course/course-1.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_1.jpeg`),
                   lesson:'43',
                   title: 'Level 2 Mathematics Qualification',
                   metaDescription:'The Level 2 Math qualification is an accredited course recognized by the National Foundation for Educational Research (NFER). It provides learners with essential mathematical skills and knowledge at a foundational level. ',
                   description:'The Level 2 Mathematics Qualification stands as a prestigious certification, holding accreditation from the esteemed National Foundation for Educational Research (NFER). This endorsement underscores the program\'s credibility and recognition within the educational sphere. Designed to cater to learners at a foundational level, this qualification offers a comprehensive curriculum aimed at fostering essential mathematical skills and knowledge. Participants enrolled in this program embark on a journey of discovery and mastery, delving into core mathematical concepts and principles essential for academic and practical success. From basic arithmetic operations to more complex algebraic equations and geometric principles, learners are equipped with the tools necessary to navigate the intricacies of mathematics with confidence. Moreover, the Level 2 Mathematics Qualification serves as a gateway to higher academic pursuits and professional endeavors. By acquiring a solid understanding of foundational mathematical concepts, learners lay a robust groundwork for future academic achievements and career advancement opportunities. Through engaging coursework, interactive learning experiences, and expert guidance, participants not only acquire mathematical proficiency but also develop problem-solving abilities and analytical thinking skills vital for success in diverse fields. Ultimately, the Level 2 Mathematics Qualification empowers learners to approach mathematical challenges with competence and clarity, setting them on a path toward academic excellence and lifelong learning.',
                   rating: '4.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Fundamentals',
                   price:'300.00',
                   oldPrice:'350.00'
               },
               {
                   id: 2,
                   courseImage: require(`@/assets/img/course/course-2.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_2.jpeg`),
                   lesson:'72',
                   title: 'Level 2 English certification',
                   metaDescription:'The Level 2 English qualification is a recognized certification that assesses proficiency in the English language at an intermediate level. Accredited by reputable educational bodies, this qualification equips learners with essential language skills, including reading, writing, speaking, and listening. ',
                   description:'Empty',
                   rating: '4.0',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Fundamentals',
                   price:'300.00',
                   oldPrice:'350.00',
                   color:'sky-blue'
               },
               {
                   id: 3,
                   courseImage: require(`@/assets/img/course/course-3.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_3.jpeg`),
                   lesson:'35',
                   title: 'Common core skills certification',
                   metaDescription:'This training is aimed at nannies that wants to be registered with Ofsted. The Common core skills describes the basic skills and Knowledge you need to have before working with infants, children and young people.',
                   description:'Empty',
                   rating: '4.3',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Core skills',
                   price:'120.00',
                   oldPrice:'180.00',
                   color:'green'
               },
               {
                   id: 4,
                   courseImage: require(`@/assets/img/course/course-4.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_4.jpeg`),
                   lesson:'60',
                   title: 'Paediatric first aid certification',
                   metaDescription:'This qualification is for Nannies and childminders seeking to get registered with Ofsted. It provides learners with the first aid skills they require to enter into the children’s workforce. The aim of the qualification is to guide and assess the development of knowledge and skills relating to paediatric first aid. ',
                   description:'Empty',
                   rating: '3.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'First aid',
                   price:'180.00',
                   oldPrice:'200.00',
                   color:'blue'
               },
               {
                   id: 5,
                   courseImage: require(`@/assets/img/course/course-5.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_5.jpeg`),
                   lesson:'28',
                   title: 'Cache Level 3 Award in Preparing to Work in Home-Based Childcare ',
                   metaDescription:'This qualification prepares learners to set up a home based childcare setting as a registered childminder .The Level 3 Award in Preparing to Work in Home Based Childcare has been developed in collaboration with the Professional Association for Childcare and Early Years (PACEY).',
                   description:'Empty',
                   rating: '4.5',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Childcare',
                   price:'300.00',
                   oldPrice:'350.00',
                   color:'orange'
               },
               {
                   id: 6,
                   courseImage: require(`@/assets/img/course/course-6.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_6.jpeg`),
                   lesson:'38',
                   title: 'Cache Level 2 Award in Adult care',
                   metaDescription:'This qualification is for Health Care forkers working in care homes and hospitals. This course will help you develop the knowledge and skills needed when working in a lead adult care worker role in care settings. It covers a wide range of areas including supporting individuals with their physical and emotional care, daily living needs and health and social care procedures.',
                   description:'Empty',
                   rating: '4.8',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Childcare',
                   price:'650.00',
                   oldPrice:'700.00',
                   color:'pink'
               },
               {
                id: 7,
                courseImage: require(`@/assets/img/course/course-6.jpg`),
                listImg:require(`@/assets/img/course/list/course_list_6.jpeg`),
                lesson:'38',
                title: 'Cache Level 3 Award in Adult care',
                metaDescription:'This qualification is for Health Care forkers working in care homes and hospitals. This course will help you develop the knowledge and skills needed when working in a lead adult care worker role in care settings. It covers a wide range of areas including supporting individuals with their physical and emotional care, daily living needs and health and social care procedures.',
                description:'Empty',
                rating: '4.8',
                teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                teacherName: 'Marlen',
                category: 'Childcare',
                price:'800.00',
                oldPrice:'850.00',
                color:'pink'
               },
               {
                   id: 8,
                   courseImage: require(`@/assets/img/course/course-7.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_7.jpeg`),
                   lesson:'26',
                   title: 'Food safety certification',
                   metaDescription:'Empty',
                   description:'Empty',
                   rating: '4.6',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Catering',
                   price:'69.00',
                   oldPrice:'72.00',
                   color:'orange'
               },
               {
                   id: 9,
                   courseImage: require(`@/assets/img/course/course-8.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_8.jpeg`),
                   lesson:'13',
                   title: 'Early years Educator course certification',
                   metaDescription:'Empty',
                   description:'Empty',
                   rating: '4.4',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Childcare',
                   price:'1200.00',
                   oldPrice:'1250.00',
                   color:'pink'
               },
               {
                   id: 10,
                   courseImage: require(`@/assets/img/course/course-9.jpg`),
                   listImg:require(`@/assets/img/course/list/course_list_9.jpeg`),
                   lesson:'25',
                   title: 'NCFE CACHE Level 4 Diploma in Adult Care',
                   metaDescription:'This qualification will support learners to develop specialist knowledge and skills in their area of responsibility and enable learners to promote a values-based culture within their setting. This qualification can act as a progression route from the Level 3 Diploma in Adult Care qualification to the Level 5 Diploma in Leadership and Management for Adult Care qualification. The qualification also aligns to the Apprenticeship Standard for Lead Practitioner in Adult Care. ',
                   description:'Empty',
                   rating: '4.2',
                   teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                   teacherName: 'Marlen',
                   category: 'Healthcare',
                   price:'1200.00',
                   oldPrice:'1970.00',
                   color:'blue-2'
               },
               {
                id: 11,
                courseImage: require(`@/assets/img/course/course-11.jpg`),
                listImg:require(`@/assets/img/course/list/course_list_11.jpg`),
                lesson:'25',
                title: 'Mandatory and Statutory Training for Nurses',
                metaDescription:'The UK Core Skills Training Framework (CSTF) aligned Mandatory and Statutory Training for nurses and healthcare professionals. ',
                description:'Empty',
                rating: '4.2',
                teacherImg: require(`@/assets/img/course/teacher/teacher-10.jpg`),
                teacherName: 'Marlen',
                category: 'Healthcare',
                price:'55.00',
                oldPrice:'75.00',
                color:'blue-2'
            },
           ]
        }
    }
}