<template>
 <section class="why__area pt-125">
   <div class="container">
      <div class="row align-items-center">
         <div class="col-xxl-5 offset-xxl-1 col-xl-5 offset-xl-1 col-lg-6 col-md-8">
            <div class="why__content pr-50 mt-40">
               <div class="section__title-wrapper mb-30">
                  <span class="section__sub-title">Why choose the Fountainlife Training Centre?</span>
                  <h2 class="section__title">Resources for <span class="yellow-bg yellow-bg-big">Educators<img src="../../assets/img/shape/yellow-bg.png" alt=""></span> and Learners</h2>
                  <p>Our courses are taught all through out the year in our training centre located in East London as well as by eLearning. Courses can also be delivered in your setting upon request.</p>
               </div>
               <div class="events__allow mb-40">
                    <ul>
                        <li><i class="fal fa-check"></i> Discover convenience at our accessible training center located in East London, or opt for the flexibility of virtual classrooms. </li>
                        <li><i class="fal fa-check"></i> At Fountainlife, ease your financial commitment by utilizing our installment plan—no need to pay everything upfront. </li>
                        <li><i class="fal fa-check"></i> Upon completing select training programs, unlock valuable work experience opportunities to apply your skills and earn simultaneously.</li>
                        <li><i class="fal fa-check"></i> Our committed assessors and support team are here to provide quality guidance throughout your course, offering employability advice to boost your career. </li>
                        <li><i class="fal fa-check"></i> Embrace the flexibility of Fountainlife's online learning platform, enabling you to balance your studies with work and other commitments from the comfort of your home. </li> 
                    </ul>
                </div>
               <div class="why__btn">
                  <router-link to="/contact" class="e-btn e-btn-3 mr-30">Join for Free</router-link>
                  <router-link to="/about" class="link-btn">
                     Learn More 
                     <i class="far fa-arrow-right"></i>
                     <i class="far fa-arrow-right"></i>
                  </router-link>
               </div>
            </div>
         </div>
         <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-8">
            <div class="why__thumb">
               <img src="../../assets/img/why/why.png" alt="">
               <img class="why-green" src="../../assets/img/why/why-shape-green.png" alt="">
               <img class="why-pink" src="../../assets/img/why/why-shape-pink.png" alt="">
               <img class="why-dot" src="../../assets/img/why/why-shape-dot.png" alt="">
               <img class="why-line" src="../../assets/img/why/why-shape-line.png" alt="">
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'WhyArea'
};
</script>

