<template>
  <header>
    <div
      id="header-sticky"
      :class="`${
        isSticky
          ? 'header__area header__transparent header__padding-2 sticky'
          : 'header__area header__transparent header__padding-2'
      }`"
    >
      <div class="container">
        <div class="row align-items-center">
          <div class="col-xxl-2 col-xl-2 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="header__left d-flex">
              <div class="logo">
                <router-link to="/">
                  <img
                    class="logo-white"
                    src="../../assets/img/logo/logo-2.png"
                    alt="logo"
                  />
                  <img
                    class="logo-black"
                    src="../../assets/img/logo/logo.png"
                    alt="logo"
                  />
                </router-link>
              </div>
            </div>
          </div>
          <div class="col-xxl-5 col-xl-5 d-none d-xl-block">
            <div class="main-menu main-menu-3">
              <nav id="mobile-menu">
                <ul>
                    <li>
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="has-dropdown">
                     <router-link to="/courses">Courses</router-link>
                     <ul class="submenu">
                        <li><router-link to="/courses">Courses</router-link></li>
                        <li><router-link to="/dbs-services">DBS Services</router-link></li>
                      </ul>
                    </li>
                    <li><router-link to="/about">About</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                    <li>
                      <router-link to="/blog">Blog</router-link>
                    </li>
                  </ul>
              </nav>
            </div>
          </div>
          <div class="col-xxl-5 col-xl-5 col-lg-6 col-md-6 col-sm-6 col-6">
            <div
              class="
                header__right
                d-flex
                justify-content-end
                align-items-center
              "
            >
              <div @click="handleSearch" class="header__search-2">
                <svg class="search-toggle" viewBox="0 0 584.4 584.4">
                  <g>
                    <g>
                      <path
                        class="st0"
                        d="M565.7,474.9l-61.1-61.1c-3.8-3.8-8.8-5.9-13.9-5.9c-6.3,0-12.1,3-15.9,8.3c-16.3,22.4-36,42.1-58.4,58.4    c-4.8,3.5-7.8,8.8-8.3,14.5c-0.4,5.6,1.7,11.3,5.8,15.4l61.1,61.1c12.1,12.1,28.2,18.8,45.4,18.8c17.1,0,33.3-6.7,45.4-18.8    C590.7,540.6,590.7,499.9,565.7,474.9z"
                      />
                      <path
                        class="st1"
                        d="M254.6,509.1c140.4,0,254.5-114.2,254.5-254.5C509.1,114.2,394.9,0,254.6,0C114.2,0,0,114.2,0,254.5    C0,394.9,114.2,509.1,254.6,509.1z M254.6,76.4c98.2,0,178.1,79.9,178.1,178.1s-79.9,178.1-178.1,178.1S76.4,352.8,76.4,254.5    S156.3,76.4,254.6,76.4z"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="header__btn header__btn-2 ml-30 d-none d-sm-block">
                <button class="e-btn" @click="logout">Log out</button>
              </div>
              <div class="sidebar__menu d-xl-none">
                <div @click="handleSidebar"
                  class="sidebar-toggle-btn sidebar-toggle-btn-white ml-30"
                  id="sidebar-toggle"
                >
                  <span class="line"></span>
                  <span class="line"></span>
                  <span class="line"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>


<!-- cart mini area start -->
<div class="cartmini__area" v-if="!loading && !error">
    <div :class="`${showMiniCart ? 'cartmini__wrapper opened' : 'cartmini__wrapper'}`">
      <div class="cartmini__title">
        <h4>Shopping cart</h4>
      </div>
      <div @click="handleCartClose" class="cartmini__close">
        <button type="button" class="cartmini__close-btn">
          <i class="fal fa-times"></i>
        </button>
      </div>
      <div class="cartmini__widget">
        <div class="cartmini__inner">
          <ul>
            <li v-for="item in cartItems" :key="item.id">
              <div class="cartmini__thumb">
                <a :href="`/course-details/${item.course.id}`">
                  <img :src="getImagePath(item.course.image_path)" :alt="item.title">
                </a>
              </div>
              <div class="cartmini__content">
                <h5><a :href="`/course-details/${item.course.id}`">{{ item.course.title }}</a></h5>
                <div class="product-quantity mt-10 mb-10">
                  <span @click="updateQuantity(item, -1)" class="cart-minus">-</span>
                  <input class="cart-input" type="text" :value="item.quantity" readonly/>
                  <span @click="updateQuantity(item, 1)" class="cart-plus">+</span>
                </div>
                <div class="product__sm-price-wrapper">
                  <span class="product__sm-price">{{ formatPrice(item.course.price) }}</span>
                </div>
              </div>
              <a href="#" @click.prevent="removeFromCart(item.course.id)" class="cartmini__del">
                <i class="fal fa-times"></i>
              </a>
            </li>
          </ul>
        </div>
        <div class="cartmini__checkout">
          <div class="cartmini__checkout-title mb-30">
            <h4>Subtotal:</h4>
            <span>{{ formatPrice(cartSubtotal) }}</span>
          </div>
          <div class="cartmini__checkout-btn">
            <router-link to="/cart" class="e-btn e-btn-border mb-10 w-100">
              <span></span> view cart
            </router-link>
            <router-link to="/checkout" class="e-btn w-100">
              <span></span> checkout
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="loading">Loading...</div>
  <div v-if="error">{{ error }}</div>

<div @click="handleCartClose" :class="[showMiniCart ? 'body-overlay opened' : 'body-overlay']"></div>
<!-- cart mini area end -->

<!-- sidebar area start -->
  <div :class="`${showSidebar ? 'sidebar__area sidebar-opened' : 'sidebar__area'}`">
      <div class="sidebar__wrapper">
        <div @click="handleSidebarClose" class="sidebar__close">
            <button class="sidebar__close-btn" id="sidebar__close-btn">
            <span><i class="fal fa-times"></i></span>
            <span>close</span>
            </button>
        </div>
        <div class="sidebar__content">
            <div class="logo mb-40">
              <router-link to="/">
                <img src="../../assets/img/logo/logo.png" alt="logo">
              </router-link>
            </div>

             <div class="side-info-content sidebar-menu mm-menu">
                  
              <ul>
                  <li>
                      <router-link to="/home">Home</router-link>
                  </li>
                  <li>
                      <router-link to="/courses">Courses</router-link>
                  </li>
                  <li>
                      <router-link to="/dbs-services">DBS Services</router-link>
                  </li>
                  <li>
                      <router-link to="/about">About</router-link>
                  </li>
                  <li>
                      <router-link to="/contact" class="border-0">Contact</router-link>
                  </li>
                  <li>
                      <router-link to="/blog">Blog</router-link>
                  </li>                  
              </ul>
            </div>

            <div class="sidebar__search p-relative mt-40 ">
              <form action="#">
                  <input type="text" placeholder="Search...">
                  <button type="submit"><i class="fad fa-search"></i></button>
              </form>
            </div>
            <div class="sidebar__cart mt-30">
              <a href="/cart">
                  <div class="header__cart-icon">
                    <svg viewBox="0 0 24 24">
                        <circle class="st0" cx="9" cy="21" r="1"/>
                        <circle class="st0" cx="20" cy="21" r="1"/>
                        <path class="st0" d="M1,1h4l2.7,13.4c0.2,1,1,1.6,2,1.6h9.7c1,0,1.8-0.7,2-1.6L23,6H6"/>
                    </svg>
                  </div>
                  <span class="cart-item">2</span>
              </a>
            </div>
        </div>
      </div>
  </div> 
  <div @click="handleSidebarClose" :class="[showSidebar ? 'body-overlay opened' : 'body-overlay']"></div>
  <!-- sidebar area end -->

</template>

<script>
import axios from 'axios';
export default {
  name: "HomeThreeHeader",
  props:{
    header__white:String,
  },
  data () {
    return {
      isSticky:false,
      showMiniCart:false,
      showSidebar:false,
      value:1,
      valueTwo:1,
      valueThree:1,
      menuOption: {
        menuShow: false,
        menuSearch: false,
        homeDropdown: false,
        coursesDropdown: false,
        pagesDropDown: false,
        blogDropdown: false,
        aboutDropdown: false,
      },
      loading: false,
      error: null,
    }
  },
  computed: {
    cartItems() {
      return this.$store.getters['cart/cartItems'];
    },
    cartSubtotal() {
      return this.$store.getters['cart/cartSubtotal'];
    },
    cartTotal() {
      return this.$store.getters['cart/cartTotal'];
    },
    isAuthenticated() {
      return this.$store.getters['isAuthenticated']; // Direct access since auth is not namespaced
    }
  },
  methods:{
      handleSticky () {
          if(window.scrollY > 80){
             this.isSticky = true;
          }
          else{
             this.isSticky = false;
          }
       },
       handleMiniCart () {
         this.showMiniCart = true;
       },
       handleCartClose () {
         this.showMiniCart = false;
       },
       handleSidebar () {
         this.showSidebar = true;
       },
       handleSidebarClose () {
         this.showSidebar = false;
       },
       handleIncreaseValue (value) {
         if(value === 'valueOne'){
           this.value++
         }
         if(value === 'valueTwo'){
           this.valueTwo++
         }
         if(value === 'valueThree'){
           this.valueThree++
         }
         
       },
       handleDecreaseValue (value) {
         if(value === 'valueOne' && this.value > 0){
           this.value--
         }
         if(value === 'valueTwo' && this.valueTwo > 0){
           this.valueTwo--
         }
         if(value === 'valueThree' && this.valueThree > 0){
           this.valueThree--
         }
       },
       getImagePath(imagePath) {
        const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:8000'; // Fallback URL
        return imagePath
          ? `${baseUrl}/storage/${imagePath}`
          : `${baseUrl}/storage/profile_photos/placeholder.jpg`; // Placeholder image URL
        },
        async fetchCart() {
          try {
            await this.$store.dispatch('cart/fetchCart');
          } catch (err) {
            this.error = 'Failed to load cart data. Please try again later.';
          } finally {
            this.loading = false;
          }
        },
        async removeFromCart(itemId) {
          try {
            this.updating = true; // Set updating to true

            const cartItem = this.cartItems.find(item => item.id === itemId);
            if (!cartItem) throw new Error(`Cart item with ID ${itemId} not found`);

            const courseId = cartItem.course.id;

            await this.$store.dispatch('cart/removeFromCart', courseId);
            await this.fetchCart();
          } catch (err) {
            console.error('Failed to remove item from cart:', err.message);
          } finally {
            this.updating = false; // Reset updating to false after operation
          }
        },
        updateQuantity(item, change) {
          const newQuantity = Math.max(1, item.quantity + change);
          this.$store.dispatch('cart/updateQuantity', { itemId: item.id, quantity: newQuantity });
        },
        formatPrice(price) {
          // Convert price to a number, default to 0 if it's not a valid number
          const numericPrice = Number(price) || 0;
          return `£${numericPrice.toFixed(2)}`;
        },
        async logout() {
        try {
          // Fetch the latest CSRF token via Sanctum endpoint
          await axios.get('/sanctum/csrf-cookie');

          // Make a POST request to the logout endpoint
          await axios.post('/logout', null, {
            headers: {
              'X-XSRF-TOKEN': this.getCsrfToken(), // Include CSRF token in the headers
            },
          });

          // Clear the token from Vuex store
          this.$store.dispatch('clearToken');

          // Reload the page to prevent token mismatch issues
          location.reload();

          // Redirect the user to the login page or any other appropriate page
          this.$router.push('/login');
        } catch (error) {
          console.error('Error logging out:', error);

          // Clear the token from Vuex store even if logout request fails
          this.$store.dispatch('clearToken');

          // Handle specific error cases if needed

          // Reload the page to prevent token mismatch issues
          location.reload();

          // Redirect the user to the login page or any other appropriate page
          this.$router.push('/login');
        }
      },
      getCsrfToken() {
        const name = 'XSRF-TOKEN=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookies = decodedCookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          let cookie = cookies[i].trim();
          if (cookie.indexOf(name) === 0) {
            return cookie.substring(name.length, cookie.length);
          }
        }
        return '';
      },
  },
  async created() {
    await this.fetchCart(); // Fetch cart data when component is created
  },
  mounted() {
      window.addEventListener('scroll',this.handleSticky)
   },
};
</script>



