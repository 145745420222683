import { createStore } from 'vuex';
import axios from 'axios';

const authModule = {
  state: {
    token: localStorage.getItem('token') || null, // Retrieve token from local storage on store initialization
    refreshToken: localStorage.getItem('refresh_token') || null, // Added refresh token to state
    user: JSON.parse(localStorage.getItem('user')) || null,
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      localStorage.setItem('token', token); // Store token in local storage
    },
    setRefreshToken(state, refreshToken) { // Added setRefreshToken mutation
      state.refreshToken = refreshToken;
      localStorage.setItem('refresh_token', refreshToken); // Store refresh token in local storage
    },
    clearToken(state) {
      state.token = null;
      state.refreshToken = null; // Clear refresh token
      localStorage.removeItem('token'); // Remove token from local storage
      localStorage.removeItem('refresh_token'); // Remove refresh token from local storage
    },
    setUser(state, user) {
      state.user = user; // Set user data in the state
      localStorage.setItem('user', JSON.stringify(user));
    },
    clearUser(state) {
      state.user = null; // Clear user data from the state
      localStorage.removeItem('user'); // Remove user data from local storage
    }
  },
  actions: {
    saveToken({ commit }, { token, refreshToken, user }) {
       console.log('Token received:', token); // Log the token received from the login response
      commit('setToken', token);
      commit('setRefreshToken', refreshToken); // Commit refresh token
      commit('setUser', user); // Commit mutation to set user data
    },
    clearToken({ commit }) {
      commit('clearToken');
      commit('clearUser'); // Commit mutation to clear user data
    },
    async fetchUser({ commit }) {
      try {
        const response = await axios.get('/api/user');
        commit('setUser', response.data);
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    },
    async refreshToken({ commit, state }) { // Added refreshToken action
      try {
        const response = await axios.post('/refresh-token', { refresh_token: state.refreshToken });
        const { access_token, refresh_token } = response.data;
        commit('setToken', access_token);
        commit('setRefreshToken', refresh_token);
      } catch (error) {
        console.error('Error refreshing token:', error);
        commit('clearToken');
        commit('clearUser');
      }
    }
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    userId(state) {
      return state.user ? state.user.id : null;
    },
    user(state) {
      return state.user; // Getter to access user data
    },
    token(state) {
      return state.token;
    },
    refreshToken(state) { // Added refreshToken getter
      return state.refreshToken;
    }
  }
};

// Cart Module
const cartModule = {
  namespaced: true, // Add this line
  state: {
    items: [],  // Initialize items as an empty array
    subtotal: 0,
    total: 0,
  },
  mutations: {
    setCart(state, cartData) {
      state.items = cartData.items || [];  // Ensure items is an array
      state.subtotal = cartData.subtotal || 0;
      state.total = cartData.total || 0;
    },
    addToCart(state, item) {
      state.items.push(item); // Add an item to the cart
    },
    removeFromCart(state, itemId) {
      state.items = state.items.filter(item => item.id !== itemId); // Remove an item from the cart
    },
    updateQuantity(state, { itemId, quantity }) {
      const item = state.items.find(item => item.id === itemId);
      if (item) {
        item.quantity = quantity;
      }
    },
    clearCart(state) {
      state.items = [];
      state.subtotal = 0;
      state.total = 0;
    },
  },
  actions: {
    async fetchCart({ commit, rootGetters }) {
      console.log('Is Authenticated:', rootGetters['isAuthenticated']); // Use rootGetters
      if (!rootGetters['isAuthenticated']) {
        console.log('User not authenticated, skipping cart fetch.');
        return;
      }

      try {
        const response = await axios.get('/api/cart');
        commit('setCart', response.data);
      } catch (error) {
        console.error('Error fetching cart:', error.response ? error.response.data : error.message);
      }
    },
    async addToCart({ commit }, item) {
      try {
        const response = await axios.post('/api/cart/add', item);
        commit('addToCart', response.data); // Assuming the API returns the added item
      } catch (error) {
        console.error('Failed to add item to cart:', error.response ? error.response.data : error.message);
      }
    },
    async removeFromCart({ commit }, courseId) {
      try {
        await axios.post('/api/cart/remove', { course_id: courseId }); // Ensure 'course_id' is sent
        commit('removeFromCart', courseId);
      } catch (error) {
        console.error('Failed to remove item from cart:', error.response ? error.response.data : error.message);
      }
    },
    async updateQuantity({ commit }, { itemId, quantity }) {
      try {
        await axios.post('/api/cart/update', { id: itemId, quantity });
        commit('updateQuantity', { itemId, quantity });
      } catch (error) {
        console.error('Failed to update item quantity:', error.response ? error.response.data : error.message);
      }
    },
    clearCart({ commit }) {
      commit('clearCart');
    },
    async processPayment({ commit, rootState }, { token, billing }) {
      try {
        // Process payment
        const paymentResponse = await axios.post('/api/checkout/process-payment', { token, billing });
    
        if (!paymentResponse.data.success) {
          console.error('Payment failed:', paymentResponse.data.message || 'Unknown error');
          return;
        }
    
        console.log('Payment successful:', paymentResponse.data);
    
        // Access the authenticated user from the auth module via rootState
        const user = rootState.auth.user;
        if (!user || !user.id) {
          console.error('User is not authenticated');
          return;
        }
    
        // Access cart items from rootState
        const cartItems = rootState.cart.items;
    
        if (!cartItems || cartItems.length === 0) {
          console.error('Cart is empty');
          return;
        }
    
        // Collect course IDs from cart items
        const courseIds = cartItems.map(item => item.course_id);
    
        // Iterate over each course ID and send a separate request
        for (const courseId of courseIds) {
          // Build the enrollments payload
          const enrollmentsPayload = {
            student_id: user.id,
            course_id: courseId // Send each course_id individually
          };
    
          console.log('Enrollment Payload:', enrollmentsPayload);
    
          // Send the enrollment request
          const enrollmentsResponse = await axios.post('/api/enrollments', enrollmentsPayload);
          console.log('Enrollment successful:', enrollmentsResponse.data);
        }
    
        // Clear the cart
        commit('clearCart');
    
      } catch (error) {
        if (error.response) {
          console.error('API error:', error.response.data);
        } else if (error.request) {
          console.error('No response received:', error.request);
        } else {
          console.error('Error setting up request:', error.message);
        }
      }
    }
    
    
    
  },
  getters: {
    cartItems(state) {
      return state.items || []; // Ensure it returns an empty array if items are undefined
    },
    cartSubtotal(state) {
      return state.items.reduce((sum, item) => sum + item.course.price * item.quantity, 0);
    },
    cartTotal(state) {
      return state.items.reduce((sum, item) => sum + item.course.price * item.quantity, 0);
    },
  }  
};

// Query Module
const queryModule = {
  namespaced: true,
  state: {
    query: {},    // To store query parameters
    fullPath: ''  // To store full path
  },
  mutations: {
    setQuery(state, { query, fullPath }) {
      state.query = query;
      state.fullPath = fullPath;  // Save full path
    },
    clearQuery(state) {
      state.query = {};
      state.fullPath = '';  // Clear full path
    },
  },
  actions: {
    saveQuery({ commit }, { query, fullPath }) {
      commit('setQuery', { query, fullPath });
    },
    clearQuery({ commit }) {
      commit('clearQuery');
    },
  },
  getters: {
    query(state) {
      return state.query;
    },
    fullPath(state) {
      return state.fullPath;
    },
    getQueryParam: (state) => (key) => {
      return state.query[key] || null;
    },
  }
};

const store = createStore({
  modules: {
    auth: authModule, // Include the auth module in the store
    cart: cartModule, // Include the cart module in the store
    query: queryModule, // Include the query module in the store
  }
});

export default store;
