<template>
    <!-- Student Header -->
    <div class="course-student-header" style="padding-top: 150px;">
    </div>
    <!-- /Student Header -->
    </template>
    <script>
    export default {
        name:'BannerModule'
    };
    </script>