<template>
 <section class="contact__area pt-115 pb-120">
   <div class="container">
      <div class="row">
         <div class="col-xxl-7 col-xl-7 col-lg-6">
            <div class="contact__wrapper">
               <div class="section__title-wrapper mb-40">
                  <h2 class="section__title">Get in<span class="yellow-bg yellow-bg-big">touch<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
                  <p>Have a question or just want to say hi? We'd love to hear from you.</p>
               </div>
               <div class="contact__form">
                  <form @submit.prevent="submitForm">
                     <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input type="text" placeholder="Your Name" v-model="name" required>
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-md-6">
                           <div class="contact__form-input">
                              <input type="email" placeholder="Your Email" v-model="email" required>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <input type="text" placeholder="Subject" v-model="subject" required>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-input">
                              <textarea placeholder="Enter Your Message" v-model="message" required></textarea>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__form-agree  d-flex align-items-center mb-20">
                              <input class="e-check-input" type="checkbox" id="e-agree" required>
                              <label class="e-check-label" for="e-agree">I agree to the<a href="#">Terms & Conditions</a></label>
                           </div>
                        </div>
                        <div class="col-xxl-12">
                           <div class="contact__btn">
                              <button type="submit" class="e-btn">Send your message</button>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
         <div class="col-xxl-4 offset-xxl-1 col-xl-4 offset-xl-1 col-lg-5 offset-lg-1">
            <div class="contact__info white-bg p-relative z-index-1">
               <div class="contact__shape">
                  <img class="contact-shape-1" src="../../assets/img/contact/contact-shape-1.png" alt="">
                  <img class="contact-shape-2" src="../../assets/img/contact/contact-shape-2.png" alt="">
                  <img class="contact-shape-3" src="../../assets/img/contact/contact-shape-3.png" alt="">
               </div>
               <div class="contact__info-inner white-bg">
                  <ul>
                     <li>
                        <div class="contact__info-item d-flex align-items-start mb-35">
                           <div class="contact__info-icon mr-15">
                              <svg class="map" viewBox="0 0 24 24">
                                 <path class="st0" d="M21,10c0,7-9,13-9,13s-9-6-9-13c0-5,4-9,9-9S21,5,21,10z"/>
                                 <circle class="st0" cx="12" cy="10" r="3"/>
                              </svg>
                           </div>
                           <div class="contact__info-text">
                              <h4>New Cross Gate Office</h4>
                              <p><a target="_blank" href="https://www.google.com/maps/place/74+Wild+Goose+Dr,+London+SE14+5LL/@51.4728182,-0.053546,17z/data=!3m1!4b1!4m6!3m5!1s0x487603aa75ccec09:0xa002582479b88db4!8m2!3d51.4728149!4d-0.0509711!16s%2Fg%2F11c4ntr8xj?entry=ttu">74 Wild Goose Dr, London SE14 5LL</a></p>

                           </div>
                        </div>
                     </li>
                     <li>
                        <div class="contact__info-item d-flex align-items-start mb-35">
                           <div class="contact__info-icon mr-15">
                              <svg class="mail" viewBox="0 0 24 24">
                                 <path class="st0" d="M4,4h16c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H4c-1.1,0-2-0.9-2-2V6C2,4.9,2.9,4,4,4z"/>
                                 <polyline class="st0" points="22,6 12,13 2,6 "/>
                              </svg>
                           </div>
                           <div class="contact__info-text">
                              <h4>Email us directly</h4>
                              <p><a href="mailto:exams@fountainlifetraining.com">exams@fountainlifetraining.com</a></p>
                              <p><a href="mailto:info@fountainlifetraining.com"> info@fountainlifetraining.com</a></p>
                           </div>
                        </div>
                     </li>
                     <li>
                        <div class="contact__info-item d-flex align-items-start mb-35">
                           <div class="contact__info-icon mr-15">
                              <svg class="call" viewBox="0 0 24 24">
                                 <path class="st0" d="M22,16.9v3c0,1.1-0.9,2-2,2c-0.1,0-0.1,0-0.2,0c-3.1-0.3-6-1.4-8.6-3.1c-2.4-1.5-4.5-3.6-6-6  c-1.7-2.6-2.7-5.6-3.1-8.7C2,3.1,2.8,2.1,3.9,2C4,2,4.1,2,4.1,2h3c1,0,1.9,0.7,2,1.7c0.1,1,0.4,1.9,0.7,2.8c0.3,0.7,0.1,1.6-0.4,2.1  L8.1,9.9c1.4,2.5,3.5,4.6,6,6l1.3-1.3c0.6-0.5,1.4-0.7,2.1-0.4c0.9,0.3,1.8,0.6,2.8,0.7C21.3,15,22,15.9,22,16.9z"/>
                                 </svg>
                           </div>
                           <div class="contact__info-text">
                              <h4>Phone</h4>
                              <p><a href="tel:+(44)-203-089-0823">+(44) 20 3089 0823</a></p>
                              <p><a href="tel:+(44)-737-619-1887">+(44) 73 7619 1887</a></p>
                           </div>
                        </div>
                     </li>
                  </ul>
                  <div class="contact__social pl-30">
                     <h4>Follow Us</h4>
                     <ul>
                        <li><a href="#" class="fb" ><i class="social_facebook"></i></a></li>
                        <li><a href="#" class="tw" ><i class="social_twitter"></i></a></li>
                        <li><a href="#" class="pin" ><i class="social_pinterest"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
 </section>
</template>

<script>
import axios from 'axios';

export default {
   name:'ContactArea',
  data() {
    return {
      
        name: '',
        email: '',
        subject: '',
        message: ''
      
    };
  },

  methods: {
      async submitForm() {
        const formData = {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message, 
        };
  
        try {
          const response = await axios.post('/api/contact', formData);
          console.log('API Response:', response.data);
          this.$emit('form-submitted', response.data.trip);
        } catch (error) {
          console.error('API Error:', error);
        }
  
        
      },
   },
};
</script>


