 <template>
    <Header/>
    <HeroSection/>
    <About/>
    <BrandArea/>
    <BannerArea/>
    <Course/>
    <Events/>
    <Pricing/>
    <Footer footerPadding="true"/>
 </template>

 <script>
import Header from '../components/Home/Header.vue';
import HeroSection from '../components/Home/HeroSection.vue';
import About from '../components/common/About.vue';
import BrandArea from '../components/common/BrandArea.vue';
import BannerArea from '../components/Home/BannerArea.vue';
import Course from '../components/Home/Courses.vue';
import Events from '../components/Home/Events.vue';
import Pricing from '../components/Home/Pricing.vue';
import Footer from '../components/Home/Footer.vue';

 export default {
     name:'HomePage',
     components:{
         Header,
         HeroSection,
         About,
         BrandArea,
         BannerArea,
         Course,
         Events,
         Pricing,
         Footer,
     }
 }
 </script>