<template>
 <section class="price__area pt-60 pb-130">
   <div class="container">
      <div class="row">
         <div class="col-xxl-4 offset-xxl-4">
            <div class="section__title-wrapper mb-60 text-center">
               <h2 class="section__title">Simple <br> All Inclusive  <span class="yellow-bg yellow-bg-big">Pricing<img src="../../assets/img/shape/yellow-bg.png" alt=""></span></h2>
               <p>No contracts. No surprise fees.</p>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-12">
            <div class="price__tab-btn text-center mb-50">
               <nav>
                  <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                     <button class="nav-link" id="nav-monthly-tab" data-bs-toggle="tab" data-bs-target="#nav-monthly" type="button" role="tab" aria-controls="nav-monthly" aria-selected="true">Online</button>
                     <button class="nav-link active" id="nav-annually-tab" data-bs-toggle="tab" data-bs-target="#nav-annually" type="button" role="tab" aria-controls="nav-annually" aria-selected="false">Onsite</button>
                  </div>
                  </nav>
            </div>
         </div>
      </div>
      <div class="row">
         <div class="col-xxl-10 offset-xxl-1 col-xl-10 offset-xl-1 col-lg-10 offset-lg-1">
            <div class="price__tab-content">
               <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade" id="nav-monthly" role="tabpanel" aria-labelledby="nav-monthly-tab">
                     <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="price__item grey-bg mb-30 p-relative">
                              <div class="price__head">
                                 <h3>Healthcare Courses</h3>
                                 <p>Perfect for registered heath workers midvives, nurses and care staffs</p>
                              </div>
                              <div class="price__tag mb-25">
                                 <span>starting at</span><h4>£55<span>.99 / person</span></h4>
                              </div>
                              <div class="price__features mb-40">
                                 <ul>
                                    <li><i class="far fa-check"></i>Courses for Healthcare Workers</li>
                                    <li><i class="far fa-check"></i>Registered Healthcare workers, Nurses and Midwives</li>
                                    <li><i class="far fa-check"></i>1-hour Mentorship and Job Interview preparation</li>
                                    <li><i class="far fa-check"></i>Accredited certifications</li>
                                 </ul>
                              </div>
                              <router-link to="/course-details/11" class="e-btn e-btn-4">Find out more</router-link>
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="price__item grey-bg mb-30 p-relative">
                              <div class="price__offer">
                                 <span>Best Value</span>
                              </div>
                              <div class="price__head">
                                 <h3>Vocational Courses</h3>
                                 <p>Perfect for registered heath workers midvives, nurses and care staffs</p>
                              </div>
                              <div class="price__tag mb-25">
                                 <span>starting at</span><h4>£55<span>.99 / person</span></h4>
                              </div>
                              <div class="price__features mb-40">
                                 <ul>
                                    <li><i class="far fa-check"></i>Courses for Healthcare Workers</li>
                                    <li><i class="far fa-check"></i>Registered Healthcare workers, Nurses and Midwives</li>
                                    <li><i class="far fa-check"></i>1-hour Mentorship and Job Interview preparation</li>
                                    <li><i class="far fa-check"></i>Accredited certifications</li>
                                 </ul>
                              </div>
                              <router-link to="/contact" class="e-btn e-btn-border">Contact us</router-link>
                           </div>
                        </div>
                     </div>
                  </div>
                  
                  <div class="tab-pane fade show active" id="nav-annually" role="tabpanel" aria-labelledby="nav-annually-tab">
                     <div class="row">
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="price__item grey-bg mb-30 p-relative">
                              <div class="price__head">
                                 <h3>Healthcare Courses</h3>
                                 <p>Perfect for registered heath workers midvives, nurses and care staffs </p>
                              </div>
                              <div class="price__tag mb-25">
                                 <span>starting at</span><h4>£55<span>.99 / person</span></h4>
                              </div>
                              <div class="price__features mb-40">
                                 <ul>
                                    <li><i class="far fa-check"></i>Courses for Healthcare Workers</li>
                                    <li><i class="far fa-check"></i>Registered Healthcare workers, Nurses and Midwives</li>
                                    <li><i class="far fa-check"></i>1-hour Mentorship and Job Interview preparation</li>
                                    <li><i class="far fa-check"></i>Accredited certifications</li>
                                 </ul>
                              </div>
                              <router-link to="/course-details/11" class="e-btn e-btn-4">Find out more</router-link>
                           </div>
                        </div>
                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                           <div class="price__item grey-bg mb-30 p-relative">
                              <div class="price__offer">
                                 <span>Best Value</span>
                              </div>
                              <div class="price__head">
                                 <h3>Vocational courses</h3>
                                 <p>Perfect for registered healthcare workers midvives, nurses and care staffs</p>
                              </div>
                              <div class="price__tag mb-25">
                                 <span>starting at</span><h4>£55<span>.99 / person</span></h4>
                              </div>
                              <div class="price__features mb-40">
                                 <ul>
                                    <li><i class="far fa-check"></i>Courses for Healthcare Workers</li>
                                    <li><i class="far fa-check"></i>Registered Healthcare workers, Nurses and Midwives</li>
                                    <li><i class="far fa-check"></i>1-hour Mentorship and Job Interview preparation</li>
                                    <li><i class="far fa-check"></i>Accredited certifications</li>
                                 </ul>
                              </div>
                              <router-link to="/contact" class="e-btn e-btn-border">Contact us</router-link>
                           </div>
                        </div>
                     </div>
                  </div>
                  </div>
            </div>
         </div>
      </div>
   </div>
</section>
</template>

<script>
export default {
   name:'PricingArea'
};
</script>
