<template>
    <section class="course-content">
        <div class="container">
            <div class="student-widget">
                <div class="student-widget-group">
                    <div class="row">
                        <div class="col-lg-12">

                            <!-- Filter -->
                            <div class="showing-list">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="show-filter choose-search-blk">
                                            <form action="#">
                                                <div class="mycourse-student align-items-center">
                                                    <div class="student-search">
                                                        <div class="search-group">
                                                            <i class="feather-search"></i>
                                                            <input type="text" class="form-control"
                                                                placeholder="Search our courses">
                                                        </div>
                                                    </div>
                                                    <div class="student-filter">
                                                        <div class="input-block select-form mb-0">
                                                            <select class="form-select select" name="sellist1">
                                                                <option>Newly published</option>
                                                                <option>Angular</option>
                                                                <option>React</option>
                                                                <option>Node</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- /Filter -->

                            <!-- Loading State -->
                            <div v-if="loading" class="text-center">
                                <p>Loading available appointments...</p>
                            </div>

                            <!-- Error State -->
                            <div v-else>
                                <div v-if="error" class="alert alert-danger">
                                    <p>{{ error }}</p>
                                </div>

                                <!-- Render Available appointments -->
                                <div>
                                    <h3>Available Slots for {{ course.title }}</h3>
                                    <ul>
                                        <li v-for="slot in availableSlots" :key="slot.id">
                                            {{ new Date(slot.available_time).toLocaleString() }}
                                            <button @click="book(slot.id)">Book</button>
                                        </li>
                                    </ul>
                                    <h3>My Appointments</h3>
                                    <ul>
                                        <li v-for="appointment in appointments" :key="appointment.id">
                                            {{ new Date(appointment.appointment_time).toLocaleString() }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- /Render Available appointments -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios';  // Import axios at the top

export default {
    name: 'AppointmentScheduler',  // Multi-word component name
    data() {
        return {
            course: {},         // To hold the course details
            availableSlots: [], // To hold available time slots
            appointments: [],   // To hold user's appointments
            loading: true,      // Loading state
            error: null,        // Error state
        };
    },
    methods: {
        // Fetch course details based on courseId
        async fetchCourse() {
            try {
                const response = await axios.get(`/api/courses/${this.courseId}`);
                this.course = response.data; // Assign fetched course details to course object
            } catch (error) {
                this.error = 'Failed to load course details. Please try again later.';
                console.error('Error fetching course details:', error.response ? error.response.data : error.message);
            }
        },
        // Fetch available time slots based on courseId
        async fetchAvailableSlots() {
            try {
                const response = await axios.get(`/api/appointments/available-slots/${this.courseId}`);
                this.availableSlots = response.data; // Assign fetched data to availableSlots
            } catch (error) {
                this.error = 'Failed to load available time slots. Please try again later.';
                console.error('Error fetching available time slots:', error.response ? error.response.data : error.message);
            } finally {
                this.loading = false; // Set loading to false after the data is fetched
            }
        },
        // Book an appointment
        book(availabilityId) {
            axios.post('/api/appointments/book', {
                availability_id: availabilityId,
                course_id: this.courseId // Pass the course_id here
            })
            .then(() => {
                this.fetchAvailableSlots();
                this.fetchAppointments();
                alert('Appointment booked successfully!');
            })
            .catch(error => {
                console.error('Error booking appointment:', error.response ? error.response.data : error.message);
                alert('Error booking appointment: ' + (error.response?.data?.error || error.message));
            });
        },

        // Fetch user's appointments
        fetchAppointments() {
            axios.get('/api/appointments/my')
                .then(response => {
                    this.appointments = response.data;
                });
        }
    },
    computed: {
        // Extract courseId from the route
        courseId() {
            return this.$route.params.courseId;
        }
    },
    mounted() {
        // Fetch course details, available slots, and user's appointments after component is mounted
        this.fetchCourse();
        this.fetchAvailableSlots();
        this.fetchAppointments();
    }
}
</script>
